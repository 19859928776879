import React from "react";
import DashboardContent from "./components/dashBoardContent";

function DashBoard() {
  return (
    <div>
      <DashboardContent />
    </div>
  );
}

export default DashBoard;
