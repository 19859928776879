/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { Box, CardContent, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { history } from "../../../app/history";
import { craeteCompany } from "../redux/companySlice";
import LocalStorage from "../../../utilities/localStorage";

function CompanyContent() {
  const dispatch = useDispatch();
  const ClientId = LocalStorage.getItem("client")?.id;
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSave = (data) => {
    setLoading(true);
    let company_details = {
      legal_name: data.legal_name,
      company_name: data.company_name,
      contact_person_email: data.contact_person_email,
      permanent_account_number: data.permanent_account_number,
      company_email: data.company_email,
      company_code: data.company_code,
      company_location: data.company_location,
    };

    dispatch(craeteCompany(company_details)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/company");
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Add New Company
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Fill all details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  Company Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter company name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="company_name"
                  {...register("company_name", {
                    required: "please enter company name",
                  })}
                  error={Boolean(errors?.company_name)}
                  helperText={errors?.company_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Legal Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter legal name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="legal_name"
                  {...register("legal_name", {
                    required: false,
                  })}
                />
              </Grid>
              {ClientId === 2 ? (
                <>
                  <Grid item md={6}>
                    <VendorCardLabel>
                      Company Code<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      placeholder="Enter Company Code"
                      size="small"
                      variant="standard"
                      style={{ width: "100%", paddingTop: "3px" }}
                      name="company_code"
                      {...register("company_code", {
                        required: "please enter company code",
                      })}
                      error={Boolean(errors?.company_code)}
                      helperText={errors?.company_code?.message}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <VendorCardLabel>
                      Company Location<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      placeholder="Enter Company Code"
                      size="small"
                      variant="standard"
                      style={{ width: "100%", paddingTop: "3px" }}
                      name="company_location"
                      {...register("company_location", {
                        required: "please enter company location",
                      })}
                      error={Boolean(errors?.company_location)}
                      helperText={errors?.company_location?.message}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item md={6}>
                <VendorCardLabel>Company Email</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  type="email"
                  placeholder="Enter company email"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="company_email"
                  {...register("company_email", {
                    required: false,
                  })}
                  // onChange={(e) => allowOnlyValidCompanyEmail(e)}
                  // value={validCompanyEmail}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Contact Person Email<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter conatct person email"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="contact_person_email"
                  {...register("contact_person_email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter valid email",
                    },
                    required: "Please enter email ",
                  })}
                  error={Boolean(errors?.contact_person_email)}
                  helperText={errors?.contact_person_email?.message}
                  // onChange={(e) => allowOnlyValidContactEmail(e)}
                  // value={validContactEmail}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  PAN Number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter PAN number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="permanent_account_number"
                  {...register("permanent_account_number", {
                    pattern: {
                      value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message: "Please enter valid PAN",
                    },
                    required: "Please Enter PAN",
                  })}
                  error={Boolean(errors?.permanent_account_number)}
                  helperText={errors?.permanent_account_number?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add Company
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default CompanyContent;
