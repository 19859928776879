import { CardContent, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { getAdminOrderDetails } from "../redux/adminOrdersSlice";
import InfoIcon from "@mui/icons-material/Info";

function AdminOrdersViewContent() {
  const { adminOrderDetails } = useSelector((state) => state.adminOrders);

  const params = useParams();
  const dispatch = useDispatch();

  const order_id = params.orderid;
  const viewOrderDetails = (order_id) => {
    dispatch(getAdminOrderDetails(order_id));
  };

  useEffect(() => {
    viewOrderDetails(order_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Order#
            {adminOrderDetails.order_number
              ? adminOrderDetails.order_number
              : "Not Applicable"}
          </Grid>
          {adminOrderDetails?.status !== "Accepted" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label"> TYPE</Typography>
              <VendorCardLabel>
                {adminOrderDetails.type
                  ? adminOrderDetails.type
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DEPARTMENT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.organizational_unit
                  ? adminOrderDetails.organizational_unit
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {adminOrderDetails.order_number
                  ? adminOrderDetails.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">AMOUNT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.order_amount
                  ? adminOrderDetails.order_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">PROJECT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.project
                  ? adminOrderDetails.project
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">VERSION NUMBER</Typography>
              <VendorCardLabel>
                {adminOrderDetails.version_number
                  ? adminOrderDetails.version_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography className="label">ORDER DOCUMENT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.Order_document ? (
                  adminOrderDetails.Order_document ? (
                    <CustomDocumentLink
                      href={adminOrderDetails.Order_document}
                      target="_blank"
                    >
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Order document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <InfoIcon
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no order document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <InfoIcon
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no order document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {adminOrderDetails?.Supporting_document !== undefined &&
              adminOrderDetails?.Supporting_document !== null &&
              adminOrderDetails?.Supporting_document.length > 0 ? (
                adminOrderDetails?.Supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <CustomDocumentLink key={index} href={item} target="_blank">
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Supporting document
                    </CustomDocumentLink>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}
export default AdminOrdersViewContent;

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
