import React from "react";
import { useDispatch } from "react-redux";
import LeftAuth from "../../common/sharedComponents/LeftScreen";
import { createNewPassword } from "./redux/createNewPasswordSlice";
import RightCreateNewPasswordScreen from "./components/createNewPasswordRightScreeen";
import { useNavigate } from "react-router-dom";

function CreateNewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (payload) => {
    dispatch(createNewPassword(payload)).then((response) => {
      navigate("/login");
    });
  };
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div style={{ position: "absolute" }}>
          <LeftAuth />
        </div>
        <div style={{ position: "absolute" }}>
          <RightCreateNewPasswordScreen onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default CreateNewPassword;
