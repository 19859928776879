import React from "react";
import { LabelText } from "../../../../assets/fonts/typography.style";
import FormItem from "../../../../common/sharedComponents/FormItem";
import {
  SignUpContainer,
  SignUpInputContainer,
  StyledInput,
} from "../../../../common/sharedComponents/styles/authRight.style";
import { FormTitleContainer } from "../forms/IndianSignup";

function IndianSignupPanRight() {
  return (
    <>
      <FormTitleContainer>PAN Information</FormTitleContainer>
      <SignUpContainer>
        <SignUpInputContainer>
          <LabelText>PAN</LabelText>
          <FormItem
            name="permanent_account_number"
            rules={[
              {
                required: true,
                message: `Enter your PAN`,
              },
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="text"
              placeholder="Enter your PAN"
            />
          </FormItem>
        </SignUpInputContainer>
        <SignUpInputContainer>
          <LabelText>PAN re-enter</LabelText>
          <FormItem
            name="confirm_permanent_account_number"
            dependencies={["permanent_account_number"]}
            /*  hasFeedback */
            rules={[
              {
                required: true,
                message: "Please confirm your PAN",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue("permanent_account_number") === value
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("The PAN numbers don't match.")
                  );
                },
              }),
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="pan"
              placeholder="Re-enter PAN"
            />
          </FormItem>
        </SignUpInputContainer>
      </SignUpContainer>
    </>
  );
}

export default IndianSignupPanRight;
