import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_notification = (payload) => {
  const role = LocalStorage.getItem("tata_login_user")?.roles[0];
  return apiClient.get(
    role === "user"
      ? `${apiClient.Urls.user_notification}`
      : role === "officer"
      ? `${apiClient.Urls.officer_notification}`
      : `${apiClient.Urls.admin_notification}`,
    payload,
    true
  );
};
