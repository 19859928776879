import React from "react";
import { LabelText } from "../../../../assets/fonts/typography.style";
import FormItem from "../../../../common/sharedComponents/FormItem";
import {
  Column,
  Row,
  SignUpContainer,
  SignUpInputContainer,
  StyledInput,
} from "../../../../common/sharedComponents/styles/authRight.style";
import { FormTitleContainer } from "../forms/IndianSignup";

function IndianSignupRight() {
  const validateCompanyName = (_, value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const gstregex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (panRegex.test(value) || gstregex.test(value)) {
      return Promise.reject("Company name cannot be in PAN or GST format");
    }
    return Promise.resolve();
  };
  return (
    <>
      <FormTitleContainer>Basic Information</FormTitleContainer>
      <SignUpContainer>
        <SignUpInputContainer>
          <Row>
            <Column>
              <LabelText>First name</LabelText>
              <FormItem
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: `Enter first name`,
                  },
                ]}
              >
                <StyledInput
                  style={{ paddingLeft: "0" }}
                  type="firstName"
                  placeholder="Enter first name"
                />
              </FormItem>
            </Column>
            <Column>
              <LabelText>Last name</LabelText>
              <FormItem
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: `Enter last name `,
                  },
                ]}
              >
                <StyledInput
                  style={{ paddingLeft: "0" }}
                  type="last_name"
                  placeholder="Enter last name"
                />
              </FormItem>
            </Column>
          </Row>
        </SignUpInputContainer>
        <SignUpInputContainer>
          <LabelText>Company name (Trade name on GST certificate)</LabelText>
          <FormItem
            name="company_name"
            rules={[
              {
                required: true,
                message: `Company Name is a required field`,
              },
              { validator: validateCompanyName },
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="company_name"
              placeholder="Enter company name"
            />
          </FormItem>
        </SignUpInputContainer>
        <SignUpInputContainer>
          <LabelText>Email</LabelText>
          <FormItem
            name="contact_person_email"
            rules={[
              {
                required: true,
                message: `Email is a required field`,
              },
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="email"
              placeholder="Enter email"
            />
          </FormItem>
        </SignUpInputContainer>
      </SignUpContainer>
    </>
  );
}

export default IndianSignupRight;
