import React from "react";
import SideBar from "../common/sharedComponents/SideBar";
import AppRouter from "../routes/AppRouter";
import { AppHolder } from "../styles/app.style";

function App() {
  return (
    <AppHolder>
      <SideBar />
      <AppRouter />
    </AppHolder>
  );
}

export default App;
