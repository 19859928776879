import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { dashBoard, banksApi } from "./dashboardAPI";
// import { history } from "../../../app/history";

const initialState = {
  loading: false,
  token: "",
  countries: [],
  vendorDetails: [],
  banks: [],
};

const actions = {
  GETDASHBOARDDETAILS: "getDashboard/GETDASHBOARDDETAILS",
  GETBANKS: "getBanks/GETBANKS",
};

export const getDashboard = createAsyncThunk(
  actions.GETDASHBOARDDETAILS,
  async (vendor_id) => {
    const response = await dashBoard(vendor_id);
    return response;
  }
);

export const getBanks = createAsyncThunk(
  actions.GETBANKS,
  async (vendor_id) => {
    const response = await banksApi(vendor_id);
    return response;
  }
);

export const dashBoardDetailsSlice = createSlice({
  name: "dashboarddetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        const { data, status_type /* cuurency  */ } = action.payload;
        if (status_type === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
        state.loading = false;
        state.vendorDetails = data;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        console.log(data);
        state.banks = data;

      })
      .addCase(getBanks.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const dashBoardDetailsActions = dashBoardDetailsSlice.actions;
export const { resetdashBoardDetails } = dashBoardDetailsSlice.actions;

export default dashBoardDetailsSlice.reducer;
