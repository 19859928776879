import React from "react";
import { StepperContentHolder } from "../adminOrders/adminOrdersView";
import CompanyViewContent from "./components/companyviewContent";
import CompanyOfficerTable from "./components/companyOfficerTable";

function CompanyView() {
  return (
    <div>
      <StepperContentHolder>
        <CompanyViewContent />
        <CompanyOfficerTable />
      </StepperContentHolder>
    </div>
  );
}

export default CompanyView;
