import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  margin: 0 0 0 22%;
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 1em;
  @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  }
`;

export const WhiteCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: auto;
`;

export const TitleHolder = styled.div`
  font-family: var(--font-family-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0 0 0 22%;
  color: #3d4576;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  /*   @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  } */
`;

export const AdminTitleHolder = styled.div`
  font-family: var(--font-family-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0;
  color: #3d4576;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  /*   @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  } */
`;

export const Seperator = styled.hr`
  margin: 0px;
  padding: 0px;
  border: 1px solid #eaeaef;
  width: 100%;
  opacity: 0.1;
`;

export const RBCardContainer = styled.div`
  position: relative;
  margin: 0 0 0 22%;
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  background-color: #f5f5f5;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 1em;
  @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  }
`;

export const RBWhiteCard = styled.div`
  /*   :nth-child(2) {
    border-left: 10px solid red;
  } */
  border-left: 10px solid #d55ee1;
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const RBWhiteCardTwo = styled.div`
  border-left: 10px solid #f29527;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const VendorCardContainer = styled.div`
  position: relative;

  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1em;
`;

export const VendorWhiteCard = styled.div`
  padding: 1em;
  margin-top: 15px;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: auto;
`;

export const StepHolder = styled.div`
  font-family: var(--font-family-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0 0 0 22%;
  color: #3d4576;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  }
`;

export const CustomDocumentLink = styled.a`
  text-decoration: none;
  color: #4059e5;
`;
