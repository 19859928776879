import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { get_notification } from "./notificationAPI";

const initialState = {
  loading: false,
  notification: [],
  notify_count: "",
};

const actions = {
  GETNOTIFICATION: "getNotification/GETNOTIFICATION",
};

export const getNotification = createAsyncThunk(
  actions.GETNOTIFICATION,
  async (payload) => {
    const response = await get_notification(payload);
    return response;
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        const { success, message: msg, data, count } = action.payload;
        state.loading = false;
        if (success) {
          state.notification = data;
          state.notify_count = count;
        } else {
          message.error(msg);
        }
      })
      .addCase(getNotification.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const noticationActions = notificationSlice.actions;
export const { resetInvoicesModuleDetails } = notificationSlice.actions;

export default notificationSlice.reducer;
