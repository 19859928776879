import React, { useEffect } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import {
  Grid,
  Button,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import AdminAuthoritieTableContent from "./components/AdminAuthoritieTableContent";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectSpan,
  VendorCardLabel,
} from "../vendorRegistration/components/StepMain";
import { Super } from "../vendorRegistration/components/steps/cardTrial";
import { useForm } from "react-hook-form";
import {
  assignSuperAdminRole,
  officerList,
} from "./redux/AdminAuthoritiesSlice";

const AdminAuthorities = () => {
  const { loading, getOfficerList } = useSelector(
    (state) => state.addGlobleFinance
  );

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(assignSuperAdminRole(data)).then((response) => {
      setOpen(false);
    });
  };
  useEffect(() => {
    dispatch(officerList(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TitleHolder styele={{ paddingBottom: "0" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Authorities
          </Grid>
          <Grid item sx={{ display: "flex", justifyContent: "end" }} xs={6}>
            {/* dropdown */}
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "2rem",
                      height: "3rem",
                    }}
                    {...bindTrigger(popupState)}
                  >
                    +
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <Link
                        to="/app/add-finance"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Create Finance
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={(popupState.close, () => setOpen(true))}>
                      Acting Super Admin
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
        </Grid>
      </TitleHolder>
      <StepperContentHolder>
        <AdminAuthoritieTableContent />
      </StepperContentHolder>

      {/* dialog  box*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Assign Role
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Assign Acting Super Admin Role</DialogContentText>
          <br />
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.email)}
              >
                <VendorCardLabel>
                  Select Email<Super>*</Super>
                </VendorCardLabel>
                <Select
                  sx={{
                    fontFamily: "var(--font-family-reg)",
                    fontSize: "14px",
                  }}
                  displayEmpty
                  defaultValue=""
                  IconComponent={() => (
                    <ExpandMore style={{ color: "#A5A5BA" }} />
                  )}
                  labelId="demo-simple-select-standard-label"
                  id="email"
                  name="email"
                  error={Boolean(errors?.email)}
                  {...register("email", {
                    required: "Please select email id",
                  })}
                >
                  <MenuItem value="">
                    <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                      Select email
                    </span>
                  </MenuItem>
                  {getOfficerList.map((item, index) => (
                    <MenuItem
                      value={item?.User?.contact_person_email}
                      key={index}
                    >
                      <SelectSpan>
                        {item?.User?.contact_person_email}
                      </SelectSpan>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.email?.message}</FormHelperText>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  Assign
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminAuthorities;
