import apiClient from "../../../utilities/apiClient";

export const getCompanyLists = (payload) => {
  return apiClient.get(`${apiClient.Urls.company_lists}`, payload, true);
};

export const addUserInCompany = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_users}`, payload, true);
};

export const addUserInCompanies = (payload) => {
  return apiClient.post(`${apiClient.Urls.addUserInCompanies}`, payload, true);
};

export const getUserLists = (payload) => {
  return apiClient.get(`${apiClient.Urls.users_details}`, payload, true);
};

export const getUserDetails = (payload) => {
  return apiClient.get(`${apiClient.Urls.users_details}/${payload}`, "", true);
};
export const getRoles = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.role_list}?companyId=${payload}`,
    "",
    true
  );
};

export const getUserCompanyList = (payload) => {
  return apiClient.get(`${apiClient.Urls.user_companies}/${payload}`, "", true);
};

export const getCompanyListDropDown = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.companyListDropdown}/${payload}`,
    "",
    true
  );
};
export const getDropdownRole = (payload) => {

  return apiClient.get(
    `${apiClient.Urls.roleListDropdown}/${payload.uid}/${payload.cid}`,
    "",
    true
  );
};
