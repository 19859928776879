import React from "react";
import styled from "styled-components";
import ViewvendorDetails from "./components/ViewvendorDetails";

function vendorview() {
  return (
    <div>
      <StepperContentHolder>
        <ViewvendorDetails />
      </StepperContentHolder>
    </div>
  );
}

export default vendorview;

export const DashBoardRow = styled.div`
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const DashBoardColumn = styled.div`
  :nth-child(2) {
    float: right;
    text-align: right;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  text-align: left;
  float: left;
  width: 50%;
  /* padding: 0px 15px 0px 0px; */
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const StepperContentHolder = styled.div`
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0 0 0 22%;
  color: #3d4576;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  }
`;
