import React from "react";
import rectangle from "../../../assets/Rectangle.png";
import {
  AuthFormContainer,
  ButtonContainer,
  ForgotPasswordContainer,
  LoginContainer,
  Navigate,
  StyledInput,
  LoginInputContainer,
  StyledInputPassword,
} from "../../../common/sharedComponents/styles/authRight.style";
import {
  FormLinkHeader,
  LabelText,
  LinkText,
} from "../../../assets/fonts/typography.style";
import "./login.css";

import { Form } from "antd";
import { NavLink } from "react-router-dom";
import FormItem from "../../../common/sharedComponents/FormItem";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { bank_info } from "../../../utilities/helper";
import { Grid } from "@mui/material";
import sg_logo from "../../../assets/PROCURE_LOGO.png";
// import LocalStorage from "../../../utilities/localStorage";

function RightLogin({ loading, onSubmit }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { contact_person_email, password } = values;
    let payload = {
      contact_person_email,
      password,
    };
    onSubmit(payload);
  };
  // const navitas_url = LocalStorage.getItem("client").domain_url;

  return (
    <div className="col-md-5 p-0 h-md-100 h-100 w-100 d-flex justify-content-end align-items-end ">
      {/* <div className="position-absolute top-0 end-0">
        <img src={ellipse} alt="ellipse" />
      </div> */}
      <div className="position-fixed bottom-0 end-0  ">
        <img src={rectangle} alt="rectangle" />
      </div>
      <div
        className="d-md-flex align-items-center h-md-100 p-5  col-md-5 justify-content-center "
        style={{ height: "100vh" }}
      >
        <AuthFormContainer style={{ height: "90vh" }}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  defaultValue
                  to="/login"
                >
                  Sign in
                </NavLink>
              </FormLinkHeader>
            </div>
            {bank_info ? (
              ""
            ) : (
              <div className="col-auto ">
                <FormLinkHeader>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/signup"
                  >
                    Sign up
                  </NavLink>
                </FormLinkHeader>
              </div>
            )}
          </div>
          <Form form={form} onFinish={onFinish}>
            <LoginContainer>
              <LoginInputContainer>
                <LabelText>Email ID</LabelText>
                <FormItem
                  name="contact_person_email"
                  rules={[
                    {
                      required: true,
                      message: `Please enter your email`,
                    },
                  ]}
                >
                  <StyledInput
                    style={{ paddingLeft: "0" }}
                    placeholder="Enter email"
                  />
                </FormItem>
              </LoginInputContainer>
              <LoginInputContainer>
                <LabelText>Password</LabelText>
                <FormItem
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `Please enter your password`,
                    },
                  ]}
                >
                  <StyledInputPassword
                    style={{
                      paddingLeft: "0",
                    }}
                    /*  type="password" */
                    placeholder="Enter password"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeTwoTone style={{ fill: "red" }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ fill: "red" }} />
                      )
                    }
                  />
                </FormItem>
              </LoginInputContainer>
            </LoginContainer>
            <ForgotPasswordContainer>
              <LinkText>
                <Navigate to="/forgot-password">Forgot Password?</Navigate>
              </LinkText>
            </ForgotPasswordContainer>

            <ButtonContainer>
              <FormLinkHeader>
                <button loading={loading} type="submit">
                  Sign in
                </button>
              </FormLinkHeader>
            </ButtonContainer>
          </Form>
          <Grid item className="d-flex justify-content-center gap-3">
            <span className="text-center d-flex align-items-end">
              Powered by
            </span>
            <img height={"40px"} src={sg_logo} alt="Procuregenie" />{" "}
          </Grid>
          <div
            className="h-25 d-flex justify-content-end align-items-end"
            style={{ fontSize: "12px", marginRight: "2rem" }}
          >
            v 1.0.5
          </div>
        </AuthFormContainer>
      </div>
    </div>
  );
}

export default RightLogin;
