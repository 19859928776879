import { CardContent, Chip, Grid, Typography } from "@mui/material";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";

import moment from "moment";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { getUserInvoiceDetails } from "../redux/invoiceModuleSlice";

function InvoiceModuleViewContent() {
  const { user_invoice_details } = useSelector((state) => state.invoiceModule);

  const params = useParams();
  const dispatch = useDispatch();


  const invoice_id = params.invoiceid;
  const viewOrderDetails = (invoice_id) => {
    dispatch(getUserInvoiceDetails(invoice_id));
  };

  useEffect(() => {
    viewOrderDetails(invoice_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Invoice#
            {user_invoice_details.invoice_number
              ? user_invoice_details.invoice_number
              : "Not Applicable"}
          </Grid>
          {user_invoice_details?.status !== "Accepted" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">COMPANY NAME</Typography>
              <VendorCardLabel>
                {user_invoice_details?.Company?.company_name
                  ? user_invoice_details?.Company?.company_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> ORDER NUMBER</Typography>
              <VendorCardLabel>
                {user_invoice_details?.Order?.order_number
                  ? user_invoice_details?.Order?.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE NUMBER</Typography>
              <VendorCardLabel>
                {user_invoice_details.invoice_number
                  ? user_invoice_details.invoice_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE DATE</Typography>
              <VendorCardLabel>
                {user_invoice_details.invoice_date
                  ? moment(user_invoice_details.invoice_date).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE AMOUNT</Typography>
              <VendorCardLabel>
                {user_invoice_details.invoice_amount
                  ? user_invoice_details.invoice_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>

            {user_invoice_details.gst_type === "sgst&cgst" ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">CGST Amount</Typography>
                  <VendorCardLabel>
                    {user_invoice_details.cgst_amount
                      ? user_invoice_details.cgst_amount
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="label">SGST Amount</Typography>
                  <VendorCardLabel>
                    {user_invoice_details.sgst_amount
                      ? user_invoice_details.sgst_amount
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              <Grid item xs={6} md={6}>
                <Typography className="label">IGST AMOUNT</Typography>
                <VendorCardLabel>
                  {user_invoice_details.igst_amount
                    ? user_invoice_details.igst_amount
                    : "Not Applicable"}
                </VendorCardLabel>
              </Grid>
            )}

            <Grid item xs={6} md={6}>
              <Typography className="label">GSTIN</Typography>
              <VendorCardLabel>
                {user_invoice_details.GSTIN
                  ? user_invoice_details.GSTIN
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOCATION</Typography>
              <VendorCardLabel>
                {user_invoice_details?.location
                  ? user_invoice_details?.location
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> BANK</Typography>
              <VendorCardLabel>
                {user_invoice_details?.Bank_information?.bank_name
                  ? user_invoice_details?.Bank_information?.bank_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">A/C No.</Typography>
              <VendorCardLabel>
                {user_invoice_details?.Bank_information?.account_number
                  ? user_invoice_details?.Bank_information?.account_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <h5>Documents</h5>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Invoice Document</Typography>
              <VendorCardLabel>
                <CustomDocumentLink
                  href={
                    user_invoice_details?.Invoice_document?.invoice_document
                  }
                  target="_blank"
                >
                  <InsertDriveFileOutlinedIcon
                    style={{
                      marginTop: "-1px",
                      marginRight: "3px",
                      height: "12px",
                      width: "12px",
                    }}
                  />
                  Invoice document
                </CustomDocumentLink>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {user_invoice_details?.Invoice_document?.supporting_documents !==
                undefined &&
              user_invoice_details?.Invoice_document?.supporting_documents !==
                null &&
              user_invoice_details?.Invoice_document?.supporting_documents
                .length > 0 ? (
                user_invoice_details?.Invoice_document?.supporting_documents.map(
                  (item, index) => (
                    <VendorCardLabel key={index}>
                      <CustomDocumentLink href={item} target="_blank">
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </VendorCardLabel>
                  )
                )
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default InvoiceModuleViewContent;

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
