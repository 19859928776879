import React from "react";
import rectangle from "../../../assets/Rectangle.png";
import {
  AuthFormContainer,
  BottomContainer,
  ButtonContainer,
  InfoSpace,
  LoginInputContainer,
  StyledInputPassword,
} from "../../../common/sharedComponents/styles/authRight.style";
import {
  FormLinkHeader,
  LabelText,
} from "../../../assets/fonts/typography.style";
import "../../login/components/login.css";

import info from "../../../assets/icon.png";
import { Form } from "antd";

import styled from "styled-components";
import FormItem from "../../../common/sharedComponents/FormItem";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

function RightCreateNewPasswordScreen({ loading, onSubmit }) {
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { new_password, confirm_password } = values;

    let payload = {
      token,
      contact_person_email: email,
      new_password,
      confirm_password,
    };

    onSubmit(payload);
  };

  return (
    <div className="col-md-5 p-0 h-md-100 h-100 w-100 d-flex justify-content-end align-items-end">
      {/* <div className="position-absolute top-0 end-0">
        <img src={ellipse} alt="ellipse" />
      </div> */}
      <div className="position-fixed bottom-0 end-0 ">
        <img src={rectangle} alt="rectangle" />
      </div>
      <div
        className="d-md-flex align-items-center h-md-100 col-md-5 p-5 justify-content-center"
        style={{ height: "100vh" }}
      >
        <AuthFormContainer style={{ height: "90vh" }}>
          <Form form={form} onFinish={onFinish}>
            <div className="row justify-content-center">
              <div className="col-auto">
                <CreateNewPass>
                  <b>{name}</b>, set up your password
                </CreateNewPass>
              </div>
            </div>
            <CreateNewContainer>
              <LoginInputContainer>
                <LabelText>
                  New password<sup style={{ color: "red" }}> *</sup>
                </LabelText>
                <FormItem
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                  /* hasFeedback */
                >
                  <StyledInputPassword
                    style={{ paddingLeft: "0" }}
                    type="password"
                    placeholder="Enter password"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeTwoTone style={{ fill: "red" }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ fill: "red" }} />
                      )
                    }
                  />
                </FormItem>
              </LoginInputContainer>
              <LoginInputContainer>
                <LabelText>
                  Confirm password<sup style={{ color: "red" }}> *</sup>
                </LabelText>
                <FormItem
                  name="confirm_password"
                  dependencies={["new_password"]}
                  /*  hasFeedback */
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("The passwords you entered don't match")
                        );
                      },
                    }),
                  ]}
                >
                  <StyledInputPassword
                    style={{ paddingLeft: "0" }}
                    type="password"
                    placeholder="Re-enter password"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeTwoTone style={{ fill: "red" }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ fill: "red" }} />
                      )
                    }
                  />
                </FormItem>
              </LoginInputContainer>
            </CreateNewContainer>
            <br />
            <ButtonContainer>
              <FormLinkHeader>
                <button loading={loading} type="submit">
                  Set password
                </button>
              </FormLinkHeader>
            </ButtonContainer>

            <BottomContainer>
              <div>
                <img src={info} alt="info" />
              </div>

              <InfoSpace>
                Password must have 8-16 characters. It should include at least 1
                number and 1 special character from {"!@#$&*"}
              </InfoSpace>
            </BottomContainer>
          </Form>
        </AuthFormContainer>
      </div>
    </div>
  );
}

export default RightCreateNewPasswordScreen;

export const CreateNewPass = styled.p`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 39px;
  padding: 1em 1em 0em 1em;
  letter-spacing: -0.02em;
`;
export const CreateNewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0em 2em 0em 2em;
  @media screen and (min-width: 900px) {
    display: flex;
  }
`;
