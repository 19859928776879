import { Grid, MenuItem, Menu } from "@mui/material";
import React, { useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminOrdersTableContent from "./components/adminOrdersTableContent";
import LoadingButton from "@mui/lab/LoadingButton";
// for dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";
import { read, utils } from "xlsx";

import { StyledOutlineButton } from "../vendorRegistration/components/StepMain";
import { addExcelData } from "./redux/adminOrdersSlice";
import { useDispatch } from "react-redux";
import excel from "../../assets/po-format.xlsx";
// dropdown dependance

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import LocalStorage from "../../utilities/localStorage";

function AdminOrdersTable() {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [emptyOpen, setEmptyOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const role = LocalStorage.getItem("tata_login_user").roles;

  const handleFileUpload = (e) => {
    const files = e.target.files;

    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length !== 0) {
            setOpen(true);
            setData(rows);
          } else {
            setEmptyOpen(true);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadData = () => {
    dispatch(addExcelData({ data })).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setOpen(false);
        setFileOpen(false);
      } else {
        setLoading(false);
        setOpen(false);
        setFileOpen(false);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyOpen(false);
    setFileOpen(false);
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Orders
          </Grid>
          {role[0] === "Admin" || role[0] === "finance" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  gap: 3,
                }}
              >
                {/* add file dialog */}
                <Dialog
                  open={fileOpen}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: "move", fontWeight: "Bold" }}
                    id="draggable-dialog-title"
                  >
                    Add Excel File
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText>
                      Add an excel file to upload
                    </DialogContentText>
                    <DialogContentText>
                      <input
                        type="file"
                        fullWidth
                        accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFileUpload}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ alignItems: "right" }}>
                    <Button
                      autoFocus
                      onClick={handleClose}
                      style={{ border: "#4059e5 solid 1px", height: "30px" }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* dialog box for conformation */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  // PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                  >
                    Upload Excel Data
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure add excel data?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ alignItems: "right" }}>
                    <Button
                      autoFocus
                      onClick={handleClose}
                      style={{ border: "#4059e5 solid 1px", height: "30px" }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{
                        textTransform: "none",
                        background: "#4059e5",
                        width: "70px",
                        height: "30px",
                      }}
                      color="primary"
                      variant="contained"
                      onClick={uploadData}
                      loading={loading}
                      loadingPosition="start"
                    >
                      Upload
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
                {/* this dialod for no data in excel */}
                <Dialog
                  open={emptyOpen}
                  onClose={handleClose}
                  // PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                  >
                    Empty File
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      please enter data in excel
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      style={{ border: "#4059e5 solid 1px", height: "30px" }}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* <AddButton
                style={{ backgroundColor: "#4059e5" }}
                replace
                to="/app/orders"
              ></AddButton> */}
                <StyledOutlineButton
                  variant="outlined"
                  component="span"
                  style={{ borderColor: "#4059e5" }}
                >
                  <a href={excel} download style={{ textDecoration: "none" }}>
                    Download Excel
                  </a>
                </StyledOutlineButton>

                {/* dropdown */}

                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "2rem",
                          height: "3rem",
                        }}
                        {...bindTrigger(popupState)}
                      >
                        +
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <Link
                            to="/app/orders"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            Create Order
                          </Link>
                        </MenuItem>
                        <MenuItem
                          onClick={(popupState.close, () => setFileOpen(true))}
                        >
                          Upload Excel
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </div>
            </Grid>
          )}
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <AdminOrdersTableContent fileOpen={fileOpen} />
      </StepperContentHolder>
    </div>
  );
}

export default AdminOrdersTable;
