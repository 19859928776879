import React from "react";
import { StepperContentHolder } from "../adminOrders/adminOrdersView";
import CompaniesUsersViewContent from "./components/companiesUserViewContent";
import UserCompanyList from "./components/userCompanyList";

function CompaniesUsersView() {
  return (
    <div>
      <StepperContentHolder>
        <CompaniesUsersViewContent />
        <UserCompanyList />
      </StepperContentHolder>
    </div>
  );
}

export default CompaniesUsersView;
