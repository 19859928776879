import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AccountSettingsContent from "./components/accountSettingsContent";

function AccountSettings() {
  return (
    <>
      <TitleHolder>Account Settings</TitleHolder>
      <StepperContentHolder>
        <AccountSettingsContent />
      </StepperContentHolder>
    </>
  );
}

export default AccountSettings;
