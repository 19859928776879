import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
// import BankDetailsContent from "./components/bankDetailsContent";
import BankDetailsContent1 from "./components/bankDetailsContent1";

function BankDetails() {
  return (
    <div>
      <TitleHolder>Add Bank Information </TitleHolder>
      <StepperContentHolder>
        <BankDetailsContent1 />
      </StepperContentHolder>
    </div>
  );
}

export default BankDetails;
