import React from "react";
import { useDispatch } from "react-redux";
import LeftAuth from "../../common/sharedComponents/LeftScreen";

import ForgotPasswordRightScreen from "./components/forgotPasswordRightScreen";
import { requestResetPasswordLink } from "../forgotPassword/redux/forgotPasswordSlice";

function ForgotPassword() {
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(requestResetPasswordLink(payload));
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div style={{ position: "absolute" }}>
          <LeftAuth />
        </div>
        <div style={{ position: "absolute" }}>
          <ForgotPasswordRightScreen onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
