import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import InvoiceModuleContent from "./components/invoiceModuleContent";

function InvoiceModule() {
  return (
    <div>
      <TitleHolder>Invoices </TitleHolder>
      <StepperContentHolder>
        <InvoiceModuleContent />
      </StepperContentHolder>
    </div>
  );
}

export default InvoiceModule;
