import React, { useState } from "react";
// import ellipse from "../../../assets/Ellipse.png";
import rectangle from "../../../assets/Rectangle.png";
import {
  AuthFormContainer,
  BottomContainer,
  InfoSpace,
  LoginInputContainer,
  RadioColumn,
  Row,
} from "../../../common/sharedComponents/styles/authRight.style";
import { FormLinkHeader } from "../../../assets/fonts/typography.style";
import "../../login/components/login.css";
import { NavLink } from "react-router-dom";
import IndianSignup from "../components/forms/IndianSignup";
import NonIndianSignup from "../components/forms/NonIndianSignup";
import styled from "styled-components";
import { Alert } from "antd";
// import info from "../../../assets/icon.png";
/* import { CheckCircleFilled } from "@ant-design/icons"; */
import { useDispatch } from "react-redux";
import { signup } from "../redux/signup/signupSlice";
import { history } from "../../../app/history";
import { Info } from "@mui/icons-material";
function SignupRightScreen() {
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(signup(payload)).then((response) => {
      if (response?.payload?.success) {
        history.push("/login", "password");
      }
    });
  };

  const [visible, setVisible] = useState(true);

  const toggleRadioButtonOn = () => {
    setVisible(true);
  };

  const toggleRadioButtonOff = () => {
    setVisible(false);
  };

  return (
    <div className="col-md-5 p-0 h-md-100 h-100 w-100 d-flex justify-content-end align-items-end ">
      {/* <div className="position-absolute top-0 end-0">
        <img src={ellipse} alt="ellipse" />
      </div> */}
      <div className="position-fixed bottom-0 end-0 ">
        <img src={rectangle} alt="rectangle" />
      </div>

      <div
        className="d-md-flex align-items-center col-md-5  h-md-100 p-5   justify-content-center "
        style={{ height: "100vh" }}
      >
        <AuthFormContainer style={{ height: "90vh" }}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/login"
                >
                  Sign in
                </NavLink>
              </FormLinkHeader>
            </div>
            <div className="col-auto ">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/signup"
                >
                  Sign up
                </NavLink>
              </FormLinkHeader>
            </div>
          </div>

          <RadioContainer>
            <LoginInputContainer>
              <Row>
                <RadioColumn>
                  <Radio>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        defaultChecked
                        onClick={toggleRadioButtonOn}
                      />
                      <span>India</span>
                    </label>
                  </Radio>
                </RadioColumn>{" "}
                <RadioColumn>
                  <Radio>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        onClick={toggleRadioButtonOff}
                      />
                      <span>Outside India</span>
                    </label>
                  </Radio>
                </RadioColumn>
              </Row>
            </LoginInputContainer>
          </RadioContainer>

          {visible ? (
            <IndianSignup onSubmit={onSubmit} visible={visible} />
          ) : (
            <NonIndianSignup onSubmit={onSubmit} visible={visible} />
          )}
          <BottomContainer>
            <div style={{marginRight:"0.3rem"}}>
              <Info color="primary"  /> 
            </div>
            <InfoSpace style={{color:"red"}}>
              Please enter the company name as it is recorded on your
              incorporation certificate.
            </InfoSpace>
          </BottomContainer>
        </AuthFormContainer>
      </div>
    </div>
  );
}

export default SignupRightScreen;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em 2em 1em 2em;
  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

export const AlertHolder = styled.div`
  padding: 0em 0em 0em 0em;
`;

export const StyledAlert = styled(Alert)`
  .anticon svg {
    display: inline-block;
    width: 25px;
    height: 20px;
  }

  .ant-alert-with-description .ant-alert-icon {
    margin-right: 15px;
  }

  border-radius: 5px;
`;

export const Radio = styled.div`
  float: left;
  width: 50%;
  font-family: var(--font-family-reg);
  font-size: 14px;
  color: #32324d;
  label input {
    position: absolute;
    left: -9999px;
  }

  label input:checked + span {
    background-color: #d9d8ff;
    white-space: nowrap;
    border: 1px solid #4945ff;
  }

  label input:checked + span:before {
    border: 1.5px solid white;
    box-shadow: 0 0 0 1px #4945ff, inset 0 0 0 0.5em #4945ff;
  }

  label span {
    white-space: nowrap;
    width: 150px;
    height: 30px;
    border: 1px solid #cfd7df;
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }

  label span:hover {
    background-color: #d6d6e5;
  }

  label span:before {
    border: 1px solid #cfd7df;
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
  }
`;
