import React from "react";
import { CardContainer, WhiteCard } from "./styles/card.style";

function Card({ children }) {
  return (
    <CardContainer>
      <WhiteCard>{children}</WhiteCard>
    </CardContainer>
  );
}

export default Card;
