import React, { useState } from "react";

import styled from "styled-components";
import { FormLinkHeader } from "../../../../assets/fonts/typography.style";
import ellipse from "../../../../assets/Ellipse.png";
import rectangle from "../../../../assets/Rectangle.png";
import {
  LoginInputContainer,
  AuthFormContainer,
  Column,
  Row,
} from "../../../../common/sharedComponents/styles/authRight.style";

import { NavLink } from "react-router-dom";
import { Radio, RadioContainer } from "../signupRightScreen";

function IndianSignupPanRight({ loading, onSubmit }) {
  const [, setVisible] = useState(true);

  const toggleRadioButtonOn = () => {
    setVisible(true);
  };

  const toggleRadioButtonOff = () => {
    setVisible(false);
  };

  return (
    <div className="col-md-5 p-0 h-md-100 ">
      <div className="position-absolute top-0 end-0">
        <img src={ellipse} alt="ellipse" />
      </div>
      <div className="position-fixed bottom-0 end-0 ">
        <img src={rectangle} alt="rectangle" />
      </div>

      <div className="d-md-flex align-items-center h-md-100 p-5 justify-content-center">
        <AuthFormContainer>
          <div className="row justify-content-center">
            <div className="col-auto">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/login"
                >
                  Sign in
                </NavLink>
              </FormLinkHeader>
            </div>
            <div className="col-auto ">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/signup"
                >
                  Sign up
                </NavLink>
              </FormLinkHeader>
            </div>
          </div>

          <RadioContainer>
            <LoginInputContainer>
              <Row>
                <Column>
                  <Radio>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        defaultChecked
                        onClick={toggleRadioButtonOn}
                      />
                      <span>India</span>
                    </label>
                  </Radio>
                </Column>
                <Column>
                  <Radio>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        onClick={toggleRadioButtonOff}
                      />
                      <span>Outside India</span>
                    </label>
                  </Radio>
                </Column>
              </Row>
            </LoginInputContainer>
          </RadioContainer>
        </AuthFormContainer>
      </div>
    </div>
  );
}

export default IndianSignupPanRight;

export const FormTitleContainer = styled.div`
  text-align: left;
  padding: 1em 2em 1em 2em;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  color: #212134;
`;
export const IconSpan = styled.span`
  > img {
    height: 11px;
    margin: -1px 1px 1px 1px;
  }
`;
