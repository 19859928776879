import { Alert, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyContainer } from "../../../utilities/helper";
import { history } from "../../../app/history";
import { getNotification } from "../redux/notificationSlice";

const NotificationContent = () => {
  const { notification } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const pushToPage = (page) => {
    if (page === "bank") history.push("/app/bank-details/add-bank");
    if (page === "invoice") history.push("/app/invoices-list");
    if (page === "order") history.push("/app/user-orders-list");
    if (page === "pending_list") history.push("/app/recomm-vcc");
  };
  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {notification.length !== 0 ? (
        notification.map((data) => (
          <Alert
            variant="standard"
            severity={data?.success}
            action={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  color="inherit"
                  variant="text"
                  size="small"
                  onClick={() => pushToPage(data.category)}
                >
                  VIEW
                </Button>
              </div>
            }
          >
            {data?.message}
          </Alert>
        ))
      ) : (
        <EmptyContainer text={"no Data found"} />
      )}
    </div>
  );
};

export default NotificationContent;
