import apiClient from "../../../utilities/apiClient";

export const sendInvitationLink = (payload) => {
  return apiClient.post(apiClient.Urls.invite_register_link, payload, true, "");
};
export const offcerList = (payload) => {
  return apiClient.get(
    apiClient.Urls.officerCompnayListForDropdown,
    payload,
    true
  );
};
