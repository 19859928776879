import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminOrdersContent from "./components/adminOrdersContent";

function AdminOrders() {
  return (
    <div>
      <TitleHolder>Orders </TitleHolder>
      <StepperContentHolder>
        <AdminOrdersContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminOrders;
