import React from "react";
import { VendorCardContainer, VendorWhiteCard } from "./styles/card.style";

function VendorCard({ children }) {
  return (
    <VendorCardContainer>
      <VendorWhiteCard>{children}</VendorWhiteCard>
    </VendorCardContainer>
  );
}

export default VendorCard;
