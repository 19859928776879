import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  addUserInCompanies,
  addUserInCompany,
  getCompanyListDropDown,
  getCompanyLists,
  getDropdownRole,
  getRoles,
  getUserCompanyList,
  getUserDetails,
  getUserLists,
} from "./companiesUserAPI";

const initialState = {
  loading: false,
  companyList: [],
  usersList: [],
  userDetails: [],
  roleList: [],
  userOfficerList: [],
  compnayDropdownList: [],
  RoleListDropdown: [],
};

const actions = {
  GETCOMPANYLIST: "getCompanyList/GETCOMPANYLIST",
  CREATEUSER: " createUser/CREATEUSER",
  GETUSERSLIST: "getUsersList/GETUSERSLIST",
  GETUSERDETAILS: "getUserDeratils/GETUSERDETAILS",
  GETROLELISTS: "getRoleList/GETROLELISTS",
  GETUSERCOMPANYLIST: "getUserCompanyList/GETUSERCOMPANYLIST",
  COMPANYDROPDOWN: "companyDropdown/COMPANYDROPDOWN",
  ROLELISTDROPDOWN: "roleListDropdown/ROLELISTDROPDOWN",
  ADDUSERINCOMPANY: "addUserInCompany/ADDUSERINCOMPANY",
};

export const getCompanyList = createAsyncThunk(
  actions.GETCOMPANYLIST,
  async (payload) => {
    const response = await getCompanyLists(payload);
    return response;
  }
);

export const createUser = createAsyncThunk(
  actions.CREATEUSER,
  async (payload) => {
    const response = await addUserInCompany(payload);
    return response;
  }
);

export const addUser = createAsyncThunk(
  actions.ADDUSERINCOMPANY,
  async (payload) => {
    const response = await addUserInCompanies(payload);
    return response;
  }
);

export const getUsersList = createAsyncThunk(
  actions.GETUSERSLIST,
  async (payload) => {
    const response = await getUserLists(payload);
    return response;
  }
);
export const usersDetails = createAsyncThunk(
  actions.GETUSERDETAILS,
  async (payload) => {
    const response = await getUserDetails(payload);
    return response;
  }
);
export const RolesLists = createAsyncThunk(
  actions.GETROLELISTS,
  async (payload) => {
    const response = await getRoles(payload);
    return response;
  }
);

export const getUserCompany = createAsyncThunk(
  actions.GETUSERCOMPANYLIST,
  async (payload) => {
    const response = await getUserCompanyList(payload);
    return response;
  }
);

export const companyDropdown = createAsyncThunk(
  actions.COMPANYDROPDOWN,
  async (payload) => {
    const response = await getCompanyListDropDown(payload);
    return response;
  }
);
export const roleDropdown = createAsyncThunk(
  actions.ROLELISTDROPDOWN,
  async (payload) => {
    const response = await getDropdownRole(payload);
    return response;
  }
);

export const companiesUserSlice = createSlice({
  name: "companiesUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.companyList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getCompanyList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.usersList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getUsersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(usersDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.userDetails = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(usersDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(RolesLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(RolesLists.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          state.roleList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(RolesLists.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getUserCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserCompany.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        if (success) {
          state.userOfficerList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserCompany.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(companyDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyDropdown.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        if (success) {
          state.compnayDropdownList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(companyDropdown.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(roleDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(roleDropdown.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        if (success) {
          state.RoleListDropdown = data;
        } else {
          // message.error(msg);
        }
      })
      .addCase(roleDropdown.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const companiesUserActions = companiesUserSlice.actions;
export const { resetCompaniesUserDetails } = companiesUserSlice.actions;

export default companiesUserSlice.reducer;
