import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import LocalStorage from "../../../../utilities/localStorage";
import { history } from "../../../../app/history";
import { panVerify } from "./panAPI";

const initialState = {
  loading: false,
  success: false,
  user: null,
};

const actions = {
  PAN: "pan/PAN",
};

export const pan = createAsyncThunk(actions.PAN, async (payload) => {
  const response = await panVerify(payload);

  if (response?.success) {
    const { success, user } = response;
    let newUser = {
      success,
      user,
    };
    history.push("/signup");
    LocalStorage.setItem("tata_signup_user", JSON.stringify(newUser));
  }

  return response;
});

export const panSlice = createSlice({
  name: "pan",
  initialState,
  reducers: {
    resetPanDetails: (state) => {
      state.success = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pan.pending, (state) => {
        state.loading = true;
      })
      .addCase(pan.fulfilled, (state, action) => {
        const { success, message: msg, user } = action.payload;
        state.loading = false;
        state.success = success;
        state.user = user;

        if (success === true) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(pan.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.success = false;
        state.loading = false;

        message.error(msg);
      });
  },
});

export const { resetPanDetails } = panSlice.actions;

export default panSlice.reducer;
