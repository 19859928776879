import { createGlobalStyle } from "styled-components";
import Regular from "../assets/fonts/SFProTextR.woff";
import Medium from "../assets/fonts/SFProTextM.woff";
import Bold from "../assets/fonts/SFProTextB.woff";
import SemiBold from "../assets/fonts/SFProTextSB.woff";

export const GlobalStyle = createGlobalStyle`

 @font-face {
     font-family: 'SFProTextR';    
     src: url(${Regular}) format('woff'); 
   }

   @font-face {
     font-family: 'SFProTextM';
     src: url(${Medium}) format('woff'); 
   }

    @font-face {
     font-family: 'SFProTextB';
     src: url(${Bold}) format('woff'); 
   }

   @font-face {
     font-family: 'SFProTextSB';
     src: url(${SemiBold}) format('woff'); 
   }

   @font-face {
     font-family: 'SFProTextHeader';    
     font-style: normal;
       color: #a5a5ba;
  font-weight: 400;
  font-size: 26px;
  font-size: 26px;
     src: url(${Regular}) format('woff'); 
   }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}


:root{
  --button-color: #1CB697;
  --font-family-reg: 'SFProTextR';
  --font-family-med: 'SFProTextM';
  --font-family-bold: 'SFProTextB';
  --font-family-semi-bold: 'SFProTextSB';
  --font-family-in: 'SFProTextHeader';
}

.ant-message .anticon {
  position: relative;
  /* top: 1px; */
  margin-right: 9px;
  font-size: 16px;
  top: -3px;
  font-family: var(--font-family-reg);
}
.ant-message  {
 
  font-size: 14px;
 font-weight: 500;
  font-family: var(--font-family-med);
}
.label{
  font-size: 12px !important;
  color: #afa8a8;
}
.typograhyvalue{
  font-size: 13px !important;
  // font-weight: bold !important;
  color: #4c4a4a !important;
}

.downArrow .MuiSvgIcon-root{
  color: white !important;
}
.MuiAccordion-root{
  border-radius: 6px !important;
}

.MuiAccordionSummary-root{
  border-radius: 4px 4px 0px 0px !important;
}

.MuiCardHeader-subheader{
  font-size:10px !important;
}

.vendor_header{
  margin-left:-202px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  padding-right: 11px;
  padding-bottom: 18px !important;
}

.stepsDiv{
   padding: 0; 
   margin: 0;   
   overflow: hidden; 
   display: inline-block;  
   border-radius: 30px; 
  }

.stepcolm{
  position: relative;
  background-color: #dcdef0;
  list-style-type: none;  
  float: left;
}

.fstone{ position: relative; z-index: 3; background-color: #f69c6a; }
.fstone div{ color: #fff;
font-family:var(--font-family-reg);
font-weight: 700;
font-size: 16px;

/* identical to box height, or 100% */


/* Neutral/0 */ }
.secone{ position: relative; left: 0px; z-index: 2; margin-left: 5px; }

.secone div{ 
 font-weight: 500;
font-size: 16px;

/* identical to box height, or 100% */


/* Neutral/600 */

color: #666687;
font-family:var(--font-family-reg);
 
}


.stepcolm div{ padding: 6px 30px 10px 30px;; display: block; text-decoration: none; }
.secone:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.fstone:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #f69c6a;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

/**************/
.fstone_1{ position: relative; z-index: 3; background-color: #c6f0c2; }
.fstone_1 div{ color: #333; }
.secone_1{ position: relative; left: 0px; z-index: 2; margin-left: 5px;background-color: #f69c6a; }
.secone_1 div{ color: #fff; }


.stepcolm div{ padding: 6px 30px 10px 30px; display: block; text-decoration: none; }
.secone_1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.fstone_1:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #c6f0c2;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.checkedCircle{
  font-size: 21px !important;
  color: #538c24;
  font-weight: 200;
  margin-top: -2px;
  margin-right: 5px;
}

.css-axpu1l{
  background-color: rgb(64 89 229) !important;
}

.css-levciy-MuiTablePagination-displayedRows {
margin:0;

}
.css-pdct74-MuiTablePagination-selectLabel {
  margin: 0;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
 border-bottom: 1px solid #dcdce4;
  }
  
.MuiTablePagination-displayedRows{

    display:none !important;

  }

  .css-e0g7ag-MuiChip-root{
    height:20px !important
  }
  .css-1x51dt5-MuiInputBase-input-MuiInput-input
  {
    padding: 1px 0 5px !important;
  }
  .invoiceErrbutn{
    font-size:9px !important;
    font-weight: bold !important;
  }

  .bFUljy.disabledCard{
    border-left: 10px solid #cac9c7 !important;
  }
  .bnAXlF .disabledCard{
    background-color: #cac9c7 !important;
  }

  .search_for_add_info{
    margin-left:17px !important;
    top:12px !important;
    width: 80% !important
  }

  .search_for_add_info > div.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
    font-size: 12px !important;
  }
  
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    padding:0px !important
  }
  .itemName > span{
   margin-left: 1px !important;
    /* margin-left: -70px; */
    font-size: 12px;
    font-family: var(--font-family-reg);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    /* width: 150px; */
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
  }
  
  
  .goods > span {
margin-left:-70px;
font-size: 12px;
font-family: var(--font-family-reg);
font-style: normal;
font-weight: 400;
font-size: 12px;
display: inline-block;
width:150px;
white-space: nowrap;

text-overflow: ellipsis
  }
  

   .goods1 > span {
margin-left: -70px;
font-size: 12px;
font-family: var(--font-family-reg);
font-style: normal;
font-weight: 400;
font-size: 12px;
display: inline-block;
margin-left: -12px;
white-space: nowrap;

text-overflow: ellipsis
  }
`;
