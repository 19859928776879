import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getLoggedInUser } from "../utilities/helper";

export const roleBasedNavigation = (role) => {
  switch (role) {
    case "super admin":
      return "/app/recomm-vcc";
    case "finance":
      return "/app/recomm-vcc";
    case "Admin":
      return "/app/recomm-vcc";
    case "user":
      return "/app";
    case "officer":
      return "/app/orders-list";
    default:
      return "/login";
  }
};

export const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export function PrivateSigninRoute({ children }) {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  let goTo = roleBasedNavigation(user?.roles || "");
  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
}
