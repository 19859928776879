import React from "react";
import { RBCardContainer, RBWhiteCard } from "./styles/card.style";

function RBCard({ children }) {
  return (
    <RBCardContainer>
      <RBWhiteCard>{children}</RBWhiteCard>
    </RBCardContainer>
  );
}

export default RBCard;
