import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminTable from "../features/adminTable/adminTable";
import BankAccounts from "../features/bankDetails/bankAccounts";
import BankDetails from "../features/bankDetails/bankDetails";
import Dashboard from "../features/dashboard/dashboard";
import VendorRegistration from "../features/vendorRegistration/vendorRegistration";
import VendorRegistrationView from "../features/vendorRegistration/vendorview";
import VendorRegistrationEdit from "../features/vendorRegistration/vendorRegistrationEdit";
import AccountSettings from "../features/accountSettings/accountSettings";
import AdminOrders from "../features/adminOrders/adminOrders";
import AdminOrdersTable from "../features/adminOrders/adminOrdersTable";
import InvoiceModuleTable from "../features/invoiceModule/invoiceModuleTable";
import InvoiceModule from "../features/invoiceModule/invoiceModule";
import UserOrdersTable from "../features/userOrders/userOrdersTable";
import UserOrdersView from "../features/userOrders/userOrdersView";
import AdminInvoicesTable from "../features/adminInvoices/adminInvoicesTable";
import AdminInvoicesView from "../features/adminInvoices/adminInvoicesView";
import AdminOrdersView from "../features/adminOrders/adminOrdersView";
import InvoiceModuleView from "../features/invoiceModule/invoiceModuleView";
import CompanyTable from "../features/company/companyTable";
import Company from "../features/company/company";
import CompanyView from "../features/company/companyView";
import CompaniesUserTable from "../features/companiesUser/companiesUserTable";
import CompaniesUser from "../features/companiesUser/companiesUser";
import CompaniesUsersView from "../features/companiesUser/companiesUserView";
import OfficerTable from "../features/adminTable/officerTable";
import OfficerCreateVendor from "../features/OfficerCreateVendor/OfficerCreateVendor";
import AdminAuthorities from "../features/admin_authirities/AdminAuthorities";
import AddFinance from "../features/admin_authirities/AddFinance";
import Notification from "../features/notification/notification";

export default function AppRouter() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="vendor-registration" element={<VendorRegistration />} />
      <Route path="bank-details/add-bank" element={<BankDetails />} />
      <Route path="bank-details" element={<BankAccounts />} />
      <Route path="account-settings" element={<AccountSettings />} />
      <Route
        path="vendor-registration-view/:id"
        element={<VendorRegistrationView />}
      />
      <Route
        path="vendor-registration-edit/:id"
        element={<VendorRegistrationEdit />}
      />
      <Route path="recomm-vcc" element={<AdminTable />} />
      <Route path="vendor" element={<OfficerTable />} />
      <Route path="orders" element={<AdminOrders />} />
      <Route path="orders-list" element={<AdminOrdersTable />} />
      <Route path="invoices-list" element={<AdminInvoicesTable />} />
      <Route path="invoices" element={<InvoiceModule />} />
      <Route path="company" element={<CompanyTable />} />
      <Route path="add-company" element={<Company />} />
      <Route path="company/:company" element={<CompanyView />} />
      <Route path="users" element={<CompaniesUserTable />} />
      <Route path="add-user" element={<CompaniesUser />} />
      <Route path="users/:user" element={<CompaniesUsersView />} />
      <Route path="user-invoices-list" element={<InvoiceModuleTable />} />
      <Route path="user-orders-list" element={<UserOrdersTable />} />
      <Route path="add-vendor" element={<OfficerCreateVendor />} />
      <Route path="authorities" element={<AdminAuthorities />} />
      <Route path="add-finance" element={<AddFinance />} />
      <Route path="notification" element={<Notification />} />

      <Route
        path="user-orders-list-view/:orderid"
        element={<UserOrdersView />}
      />
      <Route
        path="invoices-list-view/:invoiceid"
        element={<AdminInvoicesView />}
      />
      <Route path="orders-list-view/:orderid" element={<AdminOrdersView />} />
      <Route
        path="user-invoices-list-view/:invoiceid"
        element={<InvoiceModuleView />}
      />
    </Routes>
  );
}
