import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  // IconButton,
  Grid,
} from "@mui/material";
import { Info, InsertDriveFileOutlined } from "@mui/icons-material";
// import { MoreVert } from "@mui/icons-material";
// import { getLoggedInUser } from "../../utilities/helper";

import styled from "styled-components";
import { VendorCardTitle } from "../../features/adminOrders/components/adminOrdersViewContent";
import { VendorCardLabel } from "../../features/vendorRegistration/components/StepMain";
import { CustomDocumentLink } from "./styles/card.style";
const BankInfoDetails = ({ item }) => {
  const {
    bank_name,
    city,
    branch_name,
    account_name,
    account_number,
    is_primary_account,
    currency,
    ifsc_code,
    address_line_one,
  } = item.item;

  if (item.image) {
    var {
      rtgs_authorization_letter_image_name,
      rtgs_authorization_letter_image,
      cancelled_cheque_image_name,
      cancelled_cheque_image,
      bank_statement_image_name,
      bank_statement_image,
      bank_passbook_copy_with_bank_stamp_image_name,
      bank_passbook_copy_with_bank_stamp_image,
      supporting_document_image_name,
      supporting_document_image,
    } = item.image;
  }
  // const handleMoreVertClick = () => {
  //   // Perform your desired action here
  //   alert("Performing action after clicking MoreVertIcon");
  // };
  return (
    <Card>
      <CardHeader
        // action={
        //   getLoggedInUser().roles[0] !== "super admin" ||
        //   getLoggedInUser().roles[0] !== "finance" ? (
        //     <IconButton aria-label="settings">
        //       <MoreVert onClick={handleMoreVertClick} />
        //     </IconButton>
        //   ) : (
        //     ""
        //   )
        // }
        title={
          <BankName style={{ textAlign: "left", padding: "0", margin: "0" }}>
            {bank_name}
            {is_primary_account ? (
              <span
                style={{
                  fontSize: "14px",
                  borderRadius: "25px",
                  background: "#D55EE1",
                  padding: "1px 20px",
                  width: "93px",
                  height: "22px",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Primary
              </span>
            ) : (
              ""
            )}
          </BankName>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              City
            </Typography>
            <Typography> {city}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              Branch Name:
            </Typography>
            <Typography> {branch_name}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              A/C name:
            </Typography>
            <Typography> {account_name}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              A/C number:
            </Typography>
            <Typography> {account_number}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              IFSC Code:
            </Typography>
            <Typography> {ifsc_code ? ifsc_code : "Not Applicable"}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              Address:
            </Typography>
            <Typography>
              {address_line_one ? address_line_one : "Not Applicable"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="text.secondary" gutterBottom>
              Currency:
            </Typography>
            <Typography> {currency}</Typography>
          </Grid>
        </Grid>
        <br />
        <VendorCardTitle>Documets:</VendorCardTitle>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6} md={4}>
            <Typography className="label">RTGS DOCUMENT</Typography>
            <VendorCardLabel>
              {rtgs_authorization_letter_image_name ? (
                rtgs_authorization_letter_image_name ? (
                  <CustomDocumentLink
                    href={rtgs_authorization_letter_image}
                    target="_blank"
                  >
                    <InsertDriveFileOutlined
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    RTGS document
                  </CustomDocumentLink>
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no RTGS document
                  </Typography>
                )
              ) : (
                <Typography className="label">
                  <Info
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There is no RTGS document
                </Typography>
              )}
            </VendorCardLabel>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className="label">CANCELLED CHEQUE DOCUMENT</Typography>
            <VendorCardLabel>
              {cancelled_cheque_image_name ? (
                cancelled_cheque_image_name ? (
                  <CustomDocumentLink
                    href={cancelled_cheque_image}
                    target="_blank"
                  >
                    <InsertDriveFileOutlined
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Cancelled cheque document
                  </CustomDocumentLink>
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no cancelled cheque document
                  </Typography>
                )
              ) : (
                <Typography className="label">
                  <Info
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There is no cancelled cheque document
                </Typography>
              )}
            </VendorCardLabel>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className="label">BANK STATEMENT DOCUMENT</Typography>
            <VendorCardLabel>
              {bank_statement_image_name ? (
                bank_statement_image_name ? (
                  <CustomDocumentLink
                    href={bank_statement_image}
                    target="_blank"
                  >
                    <InsertDriveFileOutlined
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Bank statement document
                  </CustomDocumentLink>
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no Bank statement document document
                  </Typography>
                )
              ) : (
                <Typography className="label">
                  <Info
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />
                  There is no Bank statement document document
                </Typography>
              )}
            </VendorCardLabel>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6} md={4}>
            <Typography className="label">BANK PASSBOOK DOCUMENT</Typography>
            <VendorCardLabel>
              {bank_passbook_copy_with_bank_stamp_image_name ? (
                bank_passbook_copy_with_bank_stamp_image_name ? (
                  <CustomDocumentLink
                    href={bank_passbook_copy_with_bank_stamp_image}
                    target="_blank"
                  >
                    <InsertDriveFileOutlined
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Bank Passbook document
                  </CustomDocumentLink>
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no bank passbook document
                  </Typography>
                )
              ) : (
                <Typography className="label">
                  <Info
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There is no bank passbook document
                </Typography>
              )}
            </VendorCardLabel>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className="label">BANK PASSBOOK DOCUMENT</Typography>
            <VendorCardLabel>
              {supporting_document_image_name ? (
                supporting_document_image_name ? (
                  <CustomDocumentLink
                    href={supporting_document_image}
                    target="_blank"
                  >
                    <InsertDriveFileOutlined
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Supporting document
                  </CustomDocumentLink>
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no supporting document
                  </Typography>
                )
              ) : (
                <Typography className="label">
                  <Info
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There is no supporting document
                </Typography>
              )}
            </VendorCardLabel>
          </Grid>

          <Grid item xs={6} md={4}></Grid>
        </Grid>
        <br />
      </CardContent>
    </Card>
  );
};

export default BankInfoDetails;

export const BankName = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Neutral/900 */

  color: #212134;
`;
