import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_vendors,
  get_vendor_emails,
  orders,
  get_orders,
  view_AdminOrderDetails,
  add_excel_data,
} from "./adminOrdersAPI";

const initialState = {
  loading: false,
  vendors: [],
  vendoremails: [],
  ordersList: [],
  adminOrderDetails: [],
};
const actions = {
  GETVENDORS: "getVendors/GETVENDORS",
  GETVENDOREMAILS: "getVendorsemails/GETVENDOREMAILS",
  CREATEORDER: "createOrder/CREATEORDER",
  GETORDERS: "getOrders/GETORDERS",
  ADDEXCEL: "addExcel/ADDEXCEL",
  VIEWADMINORDERDETAILS: "getAdminOrderDetails/VIEWADMINORDERDETAILS",
};

export const addExcelData = createAsyncThunk(
  actions.ADDEXCEL,
  async (payload) => {
    const response = await add_excel_data(payload);
    return response;
  }
);
export const getVendors = createAsyncThunk(
  actions.GETVENDORS,
  async (payload) => {
    const response = await get_vendors(payload);
    return response;
  }
);

export const getVendorsemails = createAsyncThunk(
  actions.GETVENDOREMAILS,
  async (payload) => {
    const response = await get_vendor_emails(payload);
    return response;
  }
);

export const createOrder = createAsyncThunk(
  actions.CREATEORDER,
  async (payload) => {
    const response = await orders(payload);
    return response;
  }
);

export const getAdminOrderDetails = createAsyncThunk(
  actions.VIEWADMINORDERDETAILS,
  async (payload) => {
    const response = await view_AdminOrderDetails(payload);
    return response;
  }
);

export const getOrders = createAsyncThunk(
  actions.GETORDERS,
  async (payload) => {
    const response = await get_orders(payload);
    return response;
  }
);

export const adminOrdersSlice = createSlice({
  name: "adminOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendors = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorsemails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorsemails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendoremails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorsemails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAdminOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminOrderDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminOrderDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminOrderDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createOrder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(addExcelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addExcelData.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addExcelData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ordersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminOrdersActions = adminOrdersSlice.actions;
export const { resetadminOrdersDetails } = adminOrdersSlice.actions;

export default adminOrdersSlice.reducer;
