import styled from "styled-components";

export const AppHolder = styled.div`
  background-color: #f5f7f9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow: auto;
`;
