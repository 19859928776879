import React from "react";
import info from "../../../../assets/icon.png";
import VendorCard from "../../../../common/sharedComponents/VendorCard";
import styled from "styled-components";

function VendorReg() {
  return (
    <div>
      <VendorCard>
        <VendorInstructions>
          <img src={info} alt="info" /> Instructions
        </VendorInstructions>
        <br />
        <VendorListHolder>
          <li>
            Enter statutory information which includes company nature,
            constitution and GST registration type.
          </li>
          <li>
            For new registrations, Legal Name is auto-populated as per the PAN
            database. It is not editable for vendors.
          </li>
          <li>
            Ensure that your PAN is validated before you proceed to the next
            page.
          </li>
        </VendorListHolder>
      </VendorCard>
      <br />
    </div>
  );
}

export default VendorReg;

export const VendorInstructions = styled.div`
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /*   padding: 1em 1em 0em 1em; */
  text-align: left;
  float: left;
  img {
    margin-right: 8px;
  }
`;

export const VendorListHolder = styled.div`
  padding-left: 2em;
  li::marker {
    color: #666687;
  }
  li {
    :nth-child(3) {
      /*  padding-bottom: 15px; */
    }
    color: #32324d;
    padding-bottom: 5px;
    font-family: var(--font-family-reg);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;

export const UserInfoDiv = styled.div`
  padding: 10px;
  text-align: left;
  font-family: var(--font-family-reg);
  font-weight: 400;
  font-size: 12px;
  background-color: #f6f6f9;
  /* margin: 0 15px 0 15px; */
  border-radius: 4px;
`;
