import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addUser,
  companyDropdown,
  getUserCompany,
  roleDropdown,
} from "../redux/companiesUserSlice";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RemoveRedEye,
  LastPage,
  FirstPage,
  ExpandMore,
} from "@mui/icons-material";

import {
  ButtonLink,
  AddButton,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import PropTypes from "prop-types";
import moment from "moment";
import { SelectSpan } from "../../vendorRegistration/components/StepMain";
import { useForm } from "react-hook-form";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import LocalStorage from "../../../utilities/localStorage";
const EmptyContainer = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText> There are no company Officer to display</DisplayText>
    <br />
    <ButtonLink to="  /app/add-user">+ Add Officer</ButtonLink>
    <br />

    <br />
  </Grid>
);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const UserCompanyList = () => {
  const { userOfficerList, compnayDropdownList, loading, RoleListDropdown } =
    useSelector((state) => state.companiesUser);
  const clientId = LocalStorage.getItem("client").id;
  const id = useParams()?.user;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - userOfficerList.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitCompany = (data) => {
    dispatch(addUser({ ...data, uid: Number(id) })).then((res) => {
      const { success } = res.payload;
      if (success) {
        dispatch(getUserCompany(id));
        dispatch(companyDropdown(id));
        handleClose();
      }
    });
  };
  React.useEffect(() => {
    dispatch(getUserCompany(id));
    dispatch(companyDropdown(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Companies
          </Grid>
          {clientId === 3 ? (
            ""
          ) : (
            <Grid item xs={6}>
              <div style={{ textAlign: "right" }}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Add User In Company
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <CustomTableContainer component={Card}>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell>
                <TableHeaderFont>Company Name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Company Email</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Contact Email</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>PAN</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Role</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Date</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>
          {userOfficerList.lenght !== 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? userOfficerList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : userOfficerList
              ).map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/company/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {row.company_name !== null
                            ? row.company_name
                            : "Not Applicable"}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.contact_person_email !== null
                        ? row.contact_person_email
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {row.company_email !== null
                        ? row.company_email
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {row.permanent_account_number !== null
                        ? row.permanent_account_number
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {row.role_name !== null
                        ? row.role_name
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {row.createdAt !== null
                        ? moment(row.createdAt).format("DD MMM YY")
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/company/" + `${row.id}`}
                      >
                        <RemoveRedEye
                          style={{
                            fill: "royalblue",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 * emptyRows }}></TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomTableContainer>
                    <EmptyContainer />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {/* table footer  */}
          <TableFooter style={{ borderTop: "1px solid lightgray" }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={8}
                count={
                  (
                    <div>
                      <AdminTitleHolder>
                        <Grid container spacing={1}>
                          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
                            Officers List
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{ textAlign: "right" }}>
                              <AddButton
                                style={{ backgroundColor: "#4059e5" }}
                                replace
                                to="/app/add-company"
                              ></AddButton>
                            </div>
                          </Grid>
                        </Grid>
                      </AdminTitleHolder>
                      <CustomTableContainer component={Card}>
                        <Table aria-label="simple table">
                          <CustomTableHead>
                            <TableRow>
                              <TableCell>
                                <TableHeaderFont>Full Name</TableHeaderFont>
                              </TableCell>
                              <TableCell>
                                <TableHeaderFont>Company Name</TableHeaderFont>
                              </TableCell>
                              {/* <TableCell>
              <TableHeaderFont>PAN Number</TableHeaderFont>
            </TableCell> */}
                              <TableCell>
                                <TableHeaderFont>Contact Email</TableHeaderFont>
                              </TableCell>
                              <TableCell>
                                <TableHeaderFont>Role</TableHeaderFont>
                              </TableCell>
                              <TableCell>
                                <TableHeaderFont>Date</TableHeaderFont>
                              </TableCell>
                              <TableCell>
                                <TableHeaderFont>Action</TableHeaderFont>
                              </TableCell>
                            </TableRow>
                          </CustomTableHead>
                          {userOfficerList.lenght !== 0 ? (
                            <TableBody>
                              {(rowsPerPage > 0
                                ? userOfficerList.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : userOfficerList
                              ).map((row) => {
                                return (
                                  <TableRow
                                    key={row.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        // eslint-disable-next-line
                                        to={"/app/users/" + `${row.id}`}
                                      >
                                        <TableContentVendorFont>
                                          {row.first_name !== null
                                            ? row.first_name
                                            : "Not Applicable"}{" "}
                                          {row.last_name !== null
                                            ? row.last_name
                                            : "Not Applicable"}
                                        </TableContentVendorFont>
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      {row.company_name !== null
                                        ? row.company_name
                                        : "Not Applicable"}
                                    </TableCell>
                                    {/* <TableCell>
                    {row.permanent_account_number !== null
                      ? row.permanent_account_number
                      : "Not Applicable"}
                  </TableCell> */}
                                    <TableCell>
                                      {row.contact_person_email !== null
                                        ? row.contact_person_email
                                        : "Not Applicable"}
                                    </TableCell>
                                    <TableCell>Officer</TableCell>
                                    <TableCell>
                                      {row.createdAt !== null
                                        ? moment(row.createdAt).format(
                                            "DD MMM YY"
                                          )
                                        : "Not Applicable"}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        // eslint-disable-next-line
                                        to={"/app/users/" + `${row.id}`}
                                      >
                                        <RemoveRedEye
                                          style={{
                                            fill: "royalblue",
                                            height: "18px",
                                            width: "18px",
                                          }}
                                        />
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {emptyRows > 0 && (
                                <TableRow
                                  style={{ height: 0 * emptyRows }}
                                ></TableRow>
                              )}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={8}>
                                  <CustomTableContainer>
                                    <EmptyContainer />
                                  </CustomTableContainer>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}

                          {/* table footer  */}
                          <TableFooter
                            style={{ borderTop: "1px solid lightgray" }}
                          >
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={8}
                                count={userOfficerList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: {
                                    "aria-label": "rows per page",
                                  },
                                  native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </CustomTableContainer>
                    </div>
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CustomTableContainer>

      {/* Dialog box */}
      <Dialog
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   component: "form",
        //   // onSubmit: (event) => {
        //   //   event.preventDefault();
        //   //   const formData = new FormData(event.currentTarget);
        //   //   const formJson = Object.fromEntries(formData.entries());
        //   //   const email = formJson.email;
        //   //   console.log(email);
        //   //   handleClose();
        //   // },
        // }}
      >
        <DialogTitle>Select Company</DialogTitle>
        <DialogContent sx={{ width: "40vw" }}>
          <form onSubmit={handleSubmit(submitCompany)}>
            <Grid container spacing={1}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.cid)}
              >
                <p style={{ paddingTop: "0.5rem" }}>
                  Company<Super>*</Super>
                </p>
                <Select
                  sx={{
                    fontFamily: "var(--font-family-reg)",
                    fontSize: "14px",
                  }}
                  displayEmpty
                  defaultValue=""
                  IconComponent={() => (
                    <ExpandMore style={{ color: "#A5A5BA" }} />
                  )}
                  labelId="demo-simple-select-standard-label"
                  id="cid"
                  label="company id"
                  name="cid"
                  {...register("cid", {
                    required: "Please Select Comapny",
                  })}
                  onChange={(e) => {
                    dispatch(roleDropdown({ uid: id, cid: e.target.value }));
                  }}
                >
                  <MenuItem value="">
                    <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                      Select Company
                    </span>
                  </MenuItem>
                  {compnayDropdownList.map((company, index) => {
                    return (
                      <MenuItem key={index} value={company.id}>
                        <SelectSpan>{company.company_name}</SelectSpan>
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.cid?.message}</FormHelperText>
              </FormControl>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.rid)}
              >
                <p style={{ paddingTop: "0.5rem" }}>
                  Select Role<Super>*</Super>
                </p>
                <Select
                  sx={{
                    fontFamily: "var(--font-family-reg)",
                    fontSize: "14px",
                  }}
                  displayEmpty
                  defaultValue=""
                  IconComponent={() => (
                    <ExpandMore style={{ color: "#A5A5BA" }} />
                  )}
                  labelId="demo-simple-select-standard-label"
                  id="rid"
                  label="role id"
                  name="rid"
                  {...register("rid", {
                    required: "Please Select Role",
                  })}
                >
                  <MenuItem value="">
                    <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                      Select Role
                    </span>
                  </MenuItem>
                  {RoleListDropdown.map((Role, index) => {
                    return (
                      <MenuItem key={index} value={Role.id}>
                        <SelectSpan>{Role.role_name}</SelectSpan>
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.rid?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <DialogActions>
              <Button
                color="error"
                variant="contained"
                sx={{ height: "40px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{
                  textTransform: "none",
                  background: "var(--button-color)",
                  width: "100px",
                  height: "40px",
                }}
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="start"
              >
                SUBMIT
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserCompanyList;
