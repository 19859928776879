import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import NotificationContent from "./components/NotificationContent";

const Notification = () => {
  return (
    <div>
      <TitleHolder>Notification </TitleHolder>
      <StepperContentHolder>
        <NotificationContent />
      </StepperContentHolder>
    </div>
  );
};

export default Notification;
