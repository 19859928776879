import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const createbank = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  return apiClient.post(
    user.roles[0] === "user"
      ? `${apiClient.Urls.create_bank}`
      : `${apiClient.Urls.add_bank_officer}`,
    payload,
    true,
    "file"
  );
};

export const countriesApi = (payload) => {
  return apiClient.get(`${apiClient.Urls.countries_get}`, payload, true);
};

export const statesApi = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.states_get}?countryId=${payload}`,
    payload,
    true
  );
};

export const banksApi = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  return apiClient.get(
    `${apiClient.Urls.bankinformation}/${user.user_id}`,
    payload,
    true
  );
};

export const validateBank = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  return apiClient.post(
    user.roles[0] === "user"
      ? `${apiClient.Urls.validateIFSC}`
      : `${apiClient.Urls.validate_ifsc_officer}`,
    payload,
    true
  );
};
