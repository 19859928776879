import apiClient from "../../../utilities/apiClient";

export const company = (payload) => {
  return apiClient.post(`${apiClient.Urls.company}`, payload, true);
};

export const getCompany = (payload) => {
  return apiClient.get(`${apiClient.Urls.company_list}`, payload, true);
};

export const getCompanyDetails = (payload) => {
  return apiClient.get(`${apiClient.Urls.company}/${payload}`, "", true);
};

export const getCompanyOfficers = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.company_officer}/${payload}`,
    "",
    true
  );
};
