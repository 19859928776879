import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { signUp, token_status } from "./signupAPI";
import LocalStorage from "../../../../utilities/localStorage";
import { history } from "../../../../app/history";

const initialState = {
  loading: false,
  success: false,
  user: null,
  page: 0,
};

const actions = {
  SIGNUP: "signup/SIGNUP",
  TOKEN_STATUS: "tokenStatus/TOKEN_STATUS",
};

export const signup = createAsyncThunk(actions.SIGNUP, async (payload) => {
  const response = await signUp(payload);

  if (response?.success) {
    const { success, user } = response;
    let newUser = {
      success,
      user,
    };
    /*  history.push("/signup"); */
    LocalStorage.setItem("tata_signup_user", JSON.stringify(newUser));
  }

  return response;
});
export const token = createAsyncThunk(actions.TOKEN_STATUS, async (payload) => {
  const response = await token_status(payload);
  return response;
});

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    resetSignUpDetails: (state) => {
      state.success = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        const { success, message: msg, result, is_domestic } = action.payload;
        state.loading = false;
        state.success = success;
        state.data = result;
        state.page = 1;
        if (success) {
          if (result) {
            state.page = 2;
            history.push("/login");
          }
          if (is_domestic === false) {
            history.push("/login");
          }
          message.success(msg);
        } else {
          message.error(msg);
        }
      })

      .addCase(signup.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.success = false;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(token.pending, (state) => {
        state.loading = true;
      })
      .addCase(token.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
        } else {
          message.error(msg);
          history.push("/login");
        }
      })

      .addCase(token.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.success = false;
        state.loading = false;
        message.error(msg);
      });
  },
});

export const { resetSignUpDetails } = signupSlice.actions;

/* export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetSignUpDetails());
  message.success("Logged out successfully");
  history.push("/login");
}; */

export default signupSlice.reducer;
