import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const dashBoard = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.user_id;
  return apiClient.get(
    `${apiClient.Urls.dash_board}/${vendor_id}`,
    payload,
    true
  );
};

export const banksApi = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.user_id;
  return apiClient.get(
    `${apiClient.Urls.bankinformation}/${vendor_id}`,
    payload,
    true
  );
};
