import React, { useEffect } from "react";
import "./components/login.css";
import LeftAuth from "../../common/sharedComponents/LeftScreen";
import RightLogin from "./components/loginRightScreen";
import { useDispatch } from "react-redux";
import { login, tenant } from "./redux/loginSlice";
import { domainName } from "../../utilities/helper";

const Login = () => {
  const dispatch = useDispatch();
  const onSubmit = (payload) => {
    dispatch(login(payload));
  };
  useEffect(() => {
    dispatch(tenant({ domain_url: domainName }));
  }, [dispatch]);
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div style={{ position: "absolute" }}>
          <LeftAuth />
        </div>
        <div style={{ position: "absolute" }}>
          <RightLogin onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
