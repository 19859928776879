import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  useTheme,
} from "@mui/material";

import Chip from "@mui/material/Chip";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { useDispatch, useSelector } from "react-redux";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  ButtonLink,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import { getInvoices } from "../redux/invoiceModuleSlice";
import { Link } from "react-router-dom";
import moment from "moment";

const EmptyContainer = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText> There are no invoices to display</DisplayText>
    <br />
    <ButtonLink style={{ width: "auto" }} to="/app/invoices">
      + Add Invoices
    </ButtonLink>

    <br />
    <br />
  </Grid>
);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function InvoiceModuleTableContent() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { invoicesList } = useSelector((state) => state.invoiceModule);
  const dispatch = useDispatch();

  const invoices = () => {
    dispatch(getInvoices());
  };

  React.useEffect(() => {
    invoices();
    // eslint-disable-next-line
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoicesList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell align="left">
              <TableHeaderFont>Company Name</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Number</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Amount</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Location</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Invoice Date</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Status</TableHeaderFont>
            </TableCell>
          </TableRow>
        </CustomTableHead>
        {invoicesList.length !== 0 ? (
          <TableBody>
            {invoicesList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/app/user-invoices-list-view/${row.id}`}
                  >
                    <TableContentVendorFont>
                      {row.CompanyName !== null
                        ? row.CompanyName
                        : "Not Applicable"}
                    </TableContentVendorFont>
                  </Link>
                </TableCell>
                <TableCell>
                  {row.invoice_number !== null
                    ? row.invoice_number
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.invoice_amount !== null
                    ? row.invoice_amount
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.location !== null && row.location !== ""
                    ? row.location
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.invoice_date
                    ? moment(row.invoice_date).format("DD MMM YY")
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.status !== null ? (
                    <Chip
                      label={row.status.toUpperCase()}
                      color={row.status === "Pending" ? "warning" : "success"}
                      size="small"
                      style={{ fontSize: "9px" }}
                    />
                  ) : (
                    "Not Applicable"
                  )}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 0 * emptyRows }}></TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <CustomTableContainer>
                  <EmptyContainer />
                </CustomTableContainer>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        <TableFooter style={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={8}
              count={invoicesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </CustomTableContainer>
  );
}

export default InvoiceModuleTableContent;
