import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createNew } from "./createNewPasswordAPI";

import LocalStorage from "../../../utilities/localStorage";

const initialState = {
  loading: false,
  token: "",
  user: null,
  new_password: "",
  confirm_password: "",
};

const actions = {
  CREATENEW: "createNew/CREATENEW",
};

export const createNewPassword = createAsyncThunk(
  actions.CREATENEW,
  async (payload) => {
    const response = await createNew(payload);

    if (response?.token) {
      const { token, user, new_password, confirm_password } = response;
      let newUser = {
        token,
        user,
        new_password,
        confirm_password,
      };

      // history.push({ pathName: "/login", state: "password" });

      LocalStorage.setItem("tata_login_user", JSON.stringify(newUser));
    }

    return response;
  }
);

export const createNewSlice = createSlice({
  name: "createNew",
  initialState,
  reducers: {
    resetCreateNewDetails: (state) => {
      state.token = "";
      state.user = null;
      state.first_name = "";
      state.last_name = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewPassword.fulfilled, (state, action) => {
        const {
          token,
          message: msg,
          new_password,
          user,
          confirm_password,
        } = action.payload;
        state.loading = false;
        state.token = token;
        state.user = user;
        state.new_password = new_password;
        state.confirm_password = confirm_password;
        if (token) {
          message.error(msg);
        } else {
          // history.push("/login");
          message.success(msg);
        }
      })
      .addCase(createNewPassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const createNewActions = createNewSlice.actions;
export const { resetCreateNewDetails } = createNewSlice.actions;

export default createNewSlice.reducer;
