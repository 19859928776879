import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import CompaniesUserContent from "./components/comapniesUserContent";

export default function CompaniesUser() {
  return (
    <div>
      <TitleHolder>Users</TitleHolder>
      <StepperContentHolder>
        <CompaniesUserContent />
      </StepperContentHolder>
    </div>
  );
}
