// import { PAN_AGENCY_KEY, PAN_API_KEY } from "../CONSTANTS";

import { dev, domainName } from "./helper";

import LocalStorage from "./localStorage";

// export const apiUrl = "http://192.168.0.109:3000";
export const apiUrl = dev ? "http://localhost:3000" : domainName;

export const panUrl = "https://preprod.aadhaarapi.com";

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return "?" + ret.join("&");
  }
  return "";
};

const getLocalToken = () => {
  const user = LocalStorage.getItem("tata_login_user");
  const authToken = user?.token;
  if (authToken !== null && authToken !== "") {
    return `${authToken}`;
  } else {
    return "";
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    panUrl,
    signin: `${apiUrl}/api/signin`,
    signup: `${apiUrl}/api/signup`,
    vendor_reg: `${apiUrl}/api/vendor_reg`,
    contactSave: `${apiUrl}/api/contact`,
    create_new: `${apiUrl}/api/reset_password`,
    validate_pan_officer: `${apiUrl}/api/officer/validatePanOfficer`,
    vendor_reg_officer: `${apiUrl}/api/officer/vendor_reg_officer`,
    validate_gst_officer: `${apiUrl}/api/officer/gst-validate`,
    createuser: `${apiUrl}/api/officer/createUser`,
    resetPassword: `${apiUrl}/api/reset_password_link`,
    panVerification: `${panUrl}/pan`,
    goodsService: `${apiUrl}/api/goods_service`,
    categories: `${apiUrl}/api/goods_service/categories`,
    getItem: `${apiUrl}/api/goods_service/item`,
    subcategoriesOfficer: `${apiUrl}/api/officer/subCategories`,
    goodsServiceOfficer: `${apiUrl}/api/officer/GandS`,
    categoriesOfficer: `${apiUrl}/api/officer/categories`,
    getItemOfficer: `${apiUrl}/api/officer/item`,
    create_bank: `${apiUrl}/api/bank-information`,
    validate_gst: `${apiUrl}/api/validate-gst`,
    countries_get: `${apiUrl}/api/country`,
    states_get: `${apiUrl}/api/country/state`,
    add_business: `${apiUrl}/api/location`,
    add_business_officer: `${apiUrl}/api/officer/add-location`,
    add_bank_officer: `${apiUrl}/api/officer/add-bank`,
    bankinformation: `${apiUrl}/api/bank-information`,
    adminBankinformation: `${apiUrl}/api/admin/bank-information`,
    view_details: `${apiUrl}/api/vendor_reg/vendorLocation`,
    dash_board: `${apiUrl}/api/vendor_reg/vendorDetails`,
    vendor_reg_update: `${apiUrl}/api/vendor_reg/update`,
    pending_list: `${apiUrl}/api/vendor/officer/pending`,
    approved_list: `${apiUrl}/api/vendor/officer/approved`,
    view_admin_details: `${apiUrl}/api/vendor/vendorLocation`,
    view_officer_details: `${apiUrl}/api/vendor/officer/vendorLocation`,
    vendor_reg_upload: `${apiUrl}/api/vendor/upload`,
    vendor_approve: `${apiUrl}/api/vendor/update`,
    update_business: `${apiUrl}/api/location/update`,
    change_password: `${apiUrl}/api/change_password`,
    vendors_list: `${apiUrl}/api/vendor/officer/all-vendors`,
    orders: `${apiUrl}/api/order`,
    add_excel_orders: `${apiUrl}/api/vendor/officer/excel`,
    orders_list: `${apiUrl}/api/vendor/officer/orderlist`,
    invoices_list: `${apiUrl}/api/vendorDetails/invoice-list`,
    invoices: `${apiUrl}/api/vendorDetails/invoice/create-invoice`,
    order_numbers: `${apiUrl}/api/vendorDetails/orderlist`,
    gst_info: `${apiUrl}/api/vendorDetails/invoice/all-gsts`,
    location_info: `${apiUrl}/api/vendorDetails/invoice/all-locations`,
    view_order_details: `${apiUrl}/api/vendorDetails/orderbyid`,
    returned_list: `${apiUrl}/api/vendor/officer/rejected`,
    order_approve: `${apiUrl}/api/vendorDetails/acceptOrder`,
    officer_invoices_list: `${apiUrl}/api/vendor/officer/invoice-list`,
    view_invoice_details: `${apiUrl}/api/vendor/officer/Invoicebyid`,
    invoice_approve: `${apiUrl}/api/vendor/officer/acceptInvoice`,
    view_admin_order_details: `${apiUrl}/api/vendor/officer/orderbyid`,
    view_user_invoice_details: `${apiUrl}/api/vendorDetails/Invoicebyid`,
    subcategories: `${apiUrl}/api/goods_service/subCategories`,
    upload_gst_doc: `${apiUrl}/api/aws_s3`,
    order_accept_numbers: `${apiUrl}/api/vendorDetails/orderDropdowList`,
    company: `${apiUrl}/api/company`,
    company_list: `${apiUrl}/api/company/list`,
    company_lists: `${apiUrl}/api/company/lists`,
    users: `${apiUrl}/api/company/user`,
    add_users: `${apiUrl}/api/company/user/add`,
    users_details: `${apiUrl}/api/company/user/details`,
    role_list: `${apiUrl}/api/role`,
    invite_register_link: `${apiUrl}/api/activities/invite-link`,
    getALLVendorAdmin: `${apiUrl}/api/vendor/vendorList`,
    validateIFSC: `${apiUrl}/api/bank-information/validate-bank`,
    validate_ifsc_officer: `${apiUrl}/api/officer/validate-bank`,
    check_token_status: `${apiUrl}/api/token_status`,
    add_finance_globle: `${apiUrl}/api/globle-role/add-finance`,
    get_list: `${apiUrl}/api/globle-role/get-list`,
    all_officer_list: `${apiUrl}/api/globle-role/get-all-officer`,
    assign_acting_role: `${apiUrl}/api/globle-role/assign-acting-SA`,
    inactive_acting_role: `${apiUrl}/api/globle-role/inactive-acting-SA`,
    admin_order_list: `${apiUrl}/api/vendor/order-list`,
    admin_order_view: `${apiUrl}/api/vendor/order-view`,
    admin_invoice_lists: `${apiUrl}/api/vendor/invoice-list`,
    admin_invoice_view: `${apiUrl}/api/vendor/invoice-view`,
    check_tenant: `${apiUrl}/api/tenants`,
    company_officer: `${apiUrl}/api/company/officers`,
    user_companies: `${apiUrl}/api/company/user/list`,
    companyListDropdown: `${apiUrl}/api/company/dropdown`,
    addUserInCompanies: `${apiUrl}/api/company/user/multiple`,
    roleListDropdown: `${apiUrl}/api/role`,
    officerCompnayListForDropdown: `${apiUrl}/api/officer/company`,
    pushTosap: `${apiUrl}/api/sap/push`,
    fetchVendorcodeWithCompanyName: `${apiUrl}/api/vendor/vendor-code`,
    fetchVendorcodeWithCompanyNameForOfficer: `${apiUrl}/api/vendor/officer/vendor-code`,
    user_notification: `${apiUrl}/api/user-notification`,
    officer_notification: `${apiUrl}/api/officer-notification`,
    admin_notification: `${apiUrl}/api/admin-notification`,
    officer_vendor_count: `${apiUrl}/api/vendor/officer/count`,
    admin_vendor_count: `${apiUrl}/api/vendor/count`,
  },

  make: async function (url, method, params, auth, type) {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let authHeaders = {
      Authorization: getLocalToken(),
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders,
      };
    }

    if (method === "GET") {
      let queryParams = "";
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      try {
        const response = await fetch(url + queryParams, {
          method,
          headers,
        });
        return await response.json();
      } catch (e) {
        return e;
      }
    } else {
      try {
        const response_1 = await fetch(url, {
          method,
          headers: type === "file" ? authHeaders : headers,
          body: type === "file" ? params : JSON.stringify(params),
        });
        return await response_1.json();
      } catch (e_1) {
        return e_1;
      }
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, "GET", params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, "POST", params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, "PUT", params, auth);
  },

  patch: function (url, params, auth, type) {
    return apiClient.make(url, "PATCH", params, auth, type);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, "DELETE", params, auth);
  },
};

export default apiClient;
