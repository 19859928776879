import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  CheckBoxLabel,
  DocumentLabels,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { useSelector } from "react-redux";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import {
  VendorCardTitle,
  VendorListHolder,
} from "../../vendorRegistration/components/steps/vendorReg";

import { useDispatch } from "react-redux";
import { styled as materialStyles } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { addbank, validatebank } from "../redux/bankDetailsSlice";
import { history } from "../../../app/history";
import LocalStorage from "../../../utilities/localStorage";
import { bank_info } from "../../../utilities/helper";

const Input = materialStyles("input")({
  display: "none",
});

function BankDetailsContent1({ l_index, createdUser, handleBack, activeStep }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchFields = watch(["rtgs", "bankStatement", "ca"]);
  const { bankdetails } = useSelector((state) => state.bankdetails);
  const [checkedPrimary, setCheckedPrimary] = useState(false);

  const dispatch = useDispatch();

  const user = LocalStorage.getItem("tata_login_user");
  const primary_role = user.roles[0];

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckedPrimary(true);
    } else {
      setCheckedPrimary(false);
    }
  };

  const onSubmit = (payload) => {
    setLoading(true);
    dispatch(addbank(payload)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        if (primary_role === "user") {
          history.push("/app");
        }
        if (primary_role === "officer") {
          history.push("/app/vendor");
        }
      }
      setLoading(false);
    });
  };

  const [supportingDoc, setSupportingDoc] = useState("");
  const [rtgsDoc, setRtgsDoc] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState("");
  const [bankStatement, setBankStatment] = useState("");
  const [passBook, setPassBook] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleIfscChange = (value) => {
    setIfscCode(value);
  };

  const validateBank = () => {
    dispatch(validatebank({ ifsc: ifscCode })).then((response) => {
      if (response.payload.success) {
        setDisabled(false);
        setShowBankDetails(true);
      }
    });
  };

  const handleNext = (data) => {
    if (bank_info) {
      if (rtgsDoc === "" && cancelledCheque === "" && bankStatement === "") {
        return alert("Atleast one document require in mandatory fields");
      }
    }
    var formData = new FormData();
    setLoading(false);
    if (primary_role === "officer") {
      data = { ...data, user_id: createdUser.id };
    }
    formData.append("data", JSON.stringify(data));
    formData.append("account_name", data.account_name);
    formData.append("account_number", data.account_number);
    formData.append("ifsc", ifscCode);
    formData.append("address_line_one", bankdetails.ADDRESS);
    formData.append("address_line_two", bankdetails.DISTRICT);
    formData.append("branch_name", bankdetails.BRANCH);
    formData.append("bank_name", bankdetails.BANK);
    formData.append("countryCode", bankdetails.ISO3166);
    formData.append("zip_code", bankdetails.ADDRESS.split(",").pop().trim());
    formData.append("city", bankdetails.CITY);
    formData.append("state", bankdetails.STATE);

    formData.append("rtgs_authorization_letter_image", rtgsDoc);
    formData.append("cancelled_cheque_image", cancelledCheque);
    formData.append("bank_statement_image", bankStatement);
    formData.append("bank_passbook_copy_with_bank_stamp_image", passBook);
    formData.append("supporting_document_image", supportingDoc);
    formData.append("is_primary_account", checkedPrimary);

    onSubmit(formData);
  };

  const payment_info_officer = (
    <CardContent>
      <Container fixed>
        <Grid>
          <VendorCardTitle>Payment Information</VendorCardTitle>
        </Grid>
        <VendorListHolder>
          <li>
            Enter account name, account number, and IFSC Code to validate bank
            details.
          </li>
          <li>Bank details will be auto-populated once bank is validated.</li>
          <li>
            Proceed to add supporting documents of bank once bank is validated.
          </li>
        </VendorListHolder>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <VendorCardLabel>
              Account name <Super>*</Super>
            </VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              InputProps={{
                disableUnderline: false,
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              placeholder="Enter name of account"
              size="small"
              variant="standard"
              style={{ width: "100%", paddingTop: "3px" }}
              name="account_name"
              {...register("account_name", {
                required: "Please enter account name",
              })}
              error={Boolean(errors?.account_name)}
              helperText={errors.account_name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <VendorCardLabel>
              Account number<Super>*</Super>
            </VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              InputProps={{
                disableUnderline: false,
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              placeholder="Enter account number"
              size="small"
              variant="standard"
              style={{ width: "100%", paddingTop: "3px" }}
              name="account_number"
              {...register("account_number", {
                pattern: {
                  value: /-?\d*\.?\d{1,2}/,
                  message: "Invalid account number",
                },
                required: "Please Enter account number",
              })}
              error={Boolean(errors?.account_number)}
              helperText={errors.account_number?.message}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <VendorCardLabel>
              IFSC Code <Super>*</Super>
            </VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              InputProps={{
                disableUnderline: false,
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              placeholder="Enter IFSC Code"
              size="small"
              variant="standard"
              style={{ width: "100%", paddingTop: "3px" }}
              name="ifsc"
              {...register("ifsc", {
                required: "Please enter IFSC",
              })}
              onChange={(e) => handleIfscChange(e.target.value)}
              error={Boolean(errors?.ifsc)}
              value={ifscCode || ""}
              helperText={errors.ifsc?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              sx={{ width: "150px", marginTop: "10px" }}
              onClick={(e) => validateBank(e)}
            >
              Validate IFSC
            </Button>
          </Grid>
        </Grid>
        <br />
        {showBankDetails ? (
          <Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <VendorCardLabel>
                  Bank Name<Super>*</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.BANK}</b>
                </span>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <VendorCardLabel>
                  Branch Name<Super>*</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.BRANCH}</b>
                </span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <VendorCardLabel>
                  Address line 1<Super>*</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.ADDRESS}</b>
                </span>
              </Grid>
              <Grid item xs={12} md={6}>
                <VendorCardLabel>
                  District<Super>*</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.DISTRICT}</b>
                </span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <VendorCardLabel>
                  City<Super>*</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.CITY}</b>
                </span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <VendorCardLabel>
                  State<Super> *</Super>
                </VendorCardLabel>
                <span>
                  <b>{bankdetails.STATE}</b>
                </span>
              </Grid>
            </Grid>
            <br />
          </Grid>
        ) : (
          ""
        )}
      </Container>
    </CardContent>
  );

  const documents = (
    <CardContent>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <VendorCardTitle>Supporting Documents</VendorCardTitle>
          </Grid>
          <Grid item xs={6} md={6}></Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>
                Upload cancelled cheque <Super>*</Super>
              </DocumentLabels>
            </span>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.cancelledCheque)}
            >
              {cancelledCheque !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(--font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={cancelledCheque.name}
                      value={cancelledCheque.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setCancelledCheque("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="cancelledCheque">
                  <Input
                    {...register("cancelledCheque", {
                      required: bank_info
                        ? false
                        : "Please upload cancelled cheque copy",
                    })}
                    accept="*"
                    id="cancelledCheque"
                    name="cancelledCheque"
                    onChange={(e) => setCancelledCheque(e.target.files[0])}
                    type="file"
                  />
                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                  <span>{cancelledCheque.name}</span>
                </label>
              )}
              {cancelledCheque === "" ? (
                <FormHelperText>
                  {errors.cancelledCheque?.message}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        {bank_info ? (
          <p style={{ textAlign: "center", width: "50%" }}>OR</p>
        ) : (
          ""
        )}

        <br />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>
                {bank_info ? (
                  <>
                    Bank Mandate duly stamped showing Account & RTGS details
                    <Super>*</Super>
                  </>
                ) : (
                  " Upload RTGS authorization letter"
                )}
              </DocumentLabels>
            </span>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              variant="standard"
              style={{ width: "100%" }}
              error={Boolean(errors?.rtgs)}
            >
              {rtgsDoc !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(--font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={rtgsDoc.name}
                      value={rtgsDoc.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setRtgsDoc("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="rtgs">
                  <Input
                    {...register("rtgs")}
                    accept="*"
                    id="rtgs"
                    onChange={(e) => setRtgsDoc(e.target.files[0])}
                    type="file"
                  />

                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                </label>
              )}
              {rtgsDoc === "" ? (
                <FormHelperText>{errors.rtgs?.message}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        {bank_info ? (
          <p style={{ textAlign: "center", width: "50%" }}>OR</p>
        ) : (
          ""
        )}
        <br />

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>
                Upload bank statement{bank_info ? <Super>*</Super> : ""}
              </DocumentLabels>
            </span>
          </Grid>
          <Grid item xs={6}>
            {bankStatement !== "" ? (
              <>
                <span
                  style={{
                    color: "#4945FF",
                    fontSize: "13px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                >
                  <Chip
                    style={{
                      width: "150px",
                      color: "#4945FF",
                      margin: "5px",
                      borderRadius: "5px",
                      border: "1px solid #D9D8FF",
                      background: "#F0F0FF",
                    }}
                    label={bankStatement.name}
                    value={bankStatement.name}
                    name="chips"
                  />
                  <HighlightOffIcon
                    sx={{ color: "#4945FF", paddingBottom: "2px" }}
                    fontSize="medium"
                    onClick={(e) => setBankStatment("")}
                  />
                </span>
              </>
            ) : (
              <label htmlFor="bankStatement">
                <Input
                  {...register("bankStatement")}
                  accept="*"
                  id="bankStatement"
                  name="bankStatement"
                  onChange={(e) => setBankStatment(e.target.files[0])}
                  type="file"
                />
                <StyledOutlineButton variant="outlined" component="span">
                  Upload
                </StyledOutlineButton>
                <span>{bankStatement.name}</span>
              </label>
            )}
          </Grid>
        </Grid>

        <br />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>
                Upload bank passbook copy with bank stamp
              </DocumentLabels>
            </span>
          </Grid>
          <Grid item xs={6}>
            {passBook !== "" ? (
              <>
                <span
                  style={{
                    color: "#4945FF",
                    fontSize: "13px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                >
                  <Chip
                    style={{
                      width: "150px",
                      color: "#4945FF",
                      margin: "5px",
                      borderRadius: "5px",
                      border: "1px solid #D9D8FF",
                      background: "#F0F0FF",
                    }}
                    label={passBook.name}
                    value={passBook.name}
                    name="chips"
                  />
                  <HighlightOffIcon
                    sx={{ color: "#4945FF", paddingBottom: "2px" }}
                    fontSize="medium"
                    onClick={(e) => setPassBook("")}
                  />
                </span>
              </>
            ) : (
              <label htmlFor="passBook">
                <Input
                  accept="*"
                  id="passBook"
                  name="passBook"
                  onChange={(e) => setPassBook(e.target.files[0])}
                  type="file"
                />
                <StyledOutlineButton variant="outlined" component="span">
                  Upload
                </StyledOutlineButton>
                <span>{passBook.name}</span>
              </label>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span variant="outlined" component="span" style={{ width: "50%" }}>
              <DocumentLabels>Upload supporting document</DocumentLabels>
            </span>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              {supportingDoc !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(--font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={supportingDoc.name}
                      value={supportingDoc.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setSupportingDoc("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="supportingDoc">
                  <Input
                    {...register("supportingDoc", {
                      required: false,
                    })}
                    accept="*"
                    id="supportingDoc"
                    onChange={(e) => setSupportingDoc(e.target.files[0])}
                    type="file"
                  />
                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                </label>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "lightgray",
                "&.Mui-checked": {
                  color: "#4059e5",
                },
              }}
              onChange={handleChange}
            />
          }
          label={
            <CheckBoxLabel>Make this my primary bank account</CheckBoxLabel>
          }
        />
        {watchFields.filter(Boolean).length === 0 && (
          <p>Please select at least one image.</p>
        )}
      </Container>
    </CardContent>
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <StyledCard>{payment_info_officer}</StyledCard>
        <StyledCard>{documents}</StyledCard>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={!activeStep}
            onClick={handleBack}
            sx={{ mr: 1, display: "none" }}
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
            disabled={disabled}
            loading={loading}
            loadingPosition="start"
          >
            Add Bank
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default BankDetailsContent1;
