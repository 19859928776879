import {
  Button,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import { approveOrder, getOrderDetails } from "../redux/userOrdersSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { history } from "../../../app/history";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { ApproveButton } from "../../vendorRegistration/components/ViewvendorDetails";

function UserOrdersViewContent() {
  const { order_details } = useSelector((state) => state.userOrders);
  const [open, setOpen] = React.useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (order_id) => {
    const payload = { order_id: order_id, accept_order: true };
    dispatch(approveOrder(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        history.push("/app/user-orders-list");
      }
    });
  };

  const order_id = params.orderid;
  const viewOrderDetails = (order_id) => {
    dispatch(getOrderDetails(order_id));
  };

  useEffect(() => {
    viewOrderDetails(order_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Order#
            {order_details.order_number
              ? order_details.order_number
              : "Not Applicable"}
          </Grid>
          {order_details?.status !== "Accepted" ? (
            <Grid item xs={6}>
              <ApproveButton
                onClick={handleClickOpen}
                variant="outlined"
                style={{
                  height: "40px",
                  width: "150px",
                  float: "right",
                }}
              >
                Accept
              </ApproveButton>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label"> COMPANY NAME</Typography>
              <VendorCardLabel>
                {order_details.CompanyName
                  ? order_details.CompanyName
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> TYPE</Typography>
              <VendorCardLabel>
                {order_details.type ? order_details.type : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DEPARTMENT</Typography>
              <VendorCardLabel>
                {order_details.organizational_unit
                  ? order_details.organizational_unit
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {order_details.order_number
                  ? order_details.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">AMOUNT</Typography>
              <VendorCardLabel>
                {order_details.order_amount
                  ? order_details.order_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">PROJECT</Typography>
              <VendorCardLabel>
                {order_details.project
                  ? order_details.project
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">VERSION NUMBER</Typography>
              <VendorCardLabel>
                {order_details.version_number
                  ? order_details.version_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography className="label">ORDER DOCUMENT</Typography>
              <VendorCardLabel>
                {order_details.Order_document ? (
                  order_details.Order_document ? (
                    <CustomDocumentLink
                      href={order_details.Order_document}
                      target="_blank"
                    >
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Order document
                    </CustomDocumentLink>
                  ) : (
                    "Not Applicable"
                  )
                ) : (
                  "Not Applicable"
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {order_details?.Supporting_document !== undefined &&
              order_details?.Supporting_document !== null &&
              order_details?.Supporting_document.length > 0 ? (
                order_details?.Supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <CustomDocumentLink href={item} target="_blank">
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Supporting document
                    </CustomDocumentLink>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to accept this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={(e) => handleApprove(order_id)}
            autoFocus
            size="small"
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserOrdersViewContent;

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
