import styled from "styled-components";

export const LinkText = styled.p`
  font-family: var(--font-family-med);
  font-weight: 500;
  font-size: 14px;
`;

export const LabelText = styled.p`
  font-family: var(--font-family-reg);
  font-weight: 400;
  font-size: 12px;
`;

export const FormLinkHeader = styled.p`
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: -0.02em;
  :hover {
    /*  border-bottom: 3px solid var(--button-color); */
  }
`;

export const FormLinkHeaderInactive = styled.p`
  font-family: var(--font-family-reg);
  font-style: normal;
  color: #a5a5ba;
  font-weight: 400;
  font-size: 26px;
  font-size: 26px;
`;
