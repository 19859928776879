import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_banks,
  invoices,
  get_invoices,
  get_order_numbers,
  get_gst,
  get_location,
  view_UserInvoiceDetails,
} from "./invoiceModuleAPI";

const initialState = {
  loading: false,
  banks: [],
  orderNumbers: [],
  invoicesList: [],
  gsts: [],
  locations: [],
  user_invoice_details: [],
};
const actions = {
  GETBANKS: "getBanks/GETBANKS",
  CREATEINVOICE: "createInvoice/CREATEINVOICE",
  GETINVOICES: "getInvoices/GETINVOICES",
  GETORDERNUMBERS: "getOrders/GETORDERNUMBERS",
  GETGSTS: "getGST/GETGSTS",
  GETLOCATIONS: "getLocations/GETLOCATIONS",
  VIEWUSERINVOICEDETAILS: "getUserInvoiceDetails/VIEWUSERINVOICEDETAILS",
};
export const getBanks = createAsyncThunk(actions.GETBANKS, async (payload) => {
  const response = await get_banks(payload);
  return response;
});

export const createInvoice = createAsyncThunk(
  actions.CREATEINVOICE,
  async (payload) => {
    const response = await invoices(payload);
    return response;
  }
);

export const getUserInvoiceDetails = createAsyncThunk(
  actions.VIEWUSERINVOICEDETAILS,
  async (payload) => {
    const response = await view_UserInvoiceDetails(payload);
    return response;
  }
);
export const getOrders = createAsyncThunk(
  actions.GETORDERNUMBERS,
  async (payload) => {
    const response = await get_order_numbers(payload);
    return response;
  }
);

export const getInvoices = createAsyncThunk(
  actions.GETINVOICES,
  async (payload) => {
    const response = await get_invoices(payload);
    return response;
  }
);

export const getGST = createAsyncThunk(actions.GETGSTS, async (payload) => {
  const response = await get_gst(payload);
  return response;
});

export const getLocation = createAsyncThunk(
  actions.GETLOCATIONS,
  async (payload) => {
    const response = await get_location(payload);
    return response;
  }
);

export const invoiceModuleSlice = createSlice({
  name: "invoiceModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.banks = data;
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getBanks.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getUserInvoiceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInvoiceDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.user_invoice_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserInvoiceDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          /* message.error(msg); */
        }
      })
      .addCase(createInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.orderNumbers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.invoicesList = data;
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getGST.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGST.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.gsts = data;
      })
      .addCase(getGST.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.locations = data;
      })
      .addCase(getLocation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const invoicesModuleActions = invoiceModuleSlice.actions;
export const { resetInvoicesModuleDetails } = invoiceModuleSlice.actions;

export default invoiceModuleSlice.reducer;
