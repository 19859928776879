import { TableContainer, TableHead } from "@mui/material";
import { styled as materialStyles } from "@mui/material/styles";
import styled from "styled-components";

export const CustomTableHead = materialStyles(TableHead)`
  background-color: #f0f0ff;
  border: 1px solid #d9d8ff;
  height: 44px;
`;

export const CustomTableContainer = materialStyles(TableContainer)`
  border-radius: 0;
 /*  filter: drop-shadow(0px 1px 20px rgba(52, 173, 237, 0.2)); */
`;

export const TableHeaderFont = styled.div`
  font-family: var(--font-family-semi-bold);
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
`;

export const Accept = styled.span`
  border: 1px solid #c6f0c2;
  background-color: #eafbe7;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  :hover {
    background-color: #c6f0c2;
  }
`;

export const Reject = styled.span`
  border: 1px solid #ff643d;
  background-color: #eebaba;
  color: red;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  :hover {
    color: white;
    background-color: #ff643d;
  }
`;

export const TableContentFont = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Neutral/800 */

  color: #32324d;
`;
export const TableContentVendorFont = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Neutral/800 */

  color: #4945ff;
`;

export const TableLinkContainer = styled.div`
  padding-bottom: 10px;
`;
