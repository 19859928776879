import { Input } from "antd";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import {
  FormLinkHeader,
  LabelText,
  LinkText,
} from "../../../assets/fonts/typography.style";

export const AuthFormContainer = styled.div`
  position: relative;
  height: 660px;
  width: 40vw;
  background: #ffffff;
  border: 1px solid #eaeaef;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(44, 92, 197, 0.2);
  border-radius: 10px 10px 0px 0px;
  padding: 2em;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 961px) {
    width: 100%;
    height: auto;
  }
`;

export const StyledHeaderLink = styled(NavLink)`
  position: relative;
  padding-bottom: 0.4rem;
  color: #212134;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  text-decoration: none;
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;

    background-color: var(--button-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;

    @media screen and (max-width: 550px) {
      margin-top: 5px;
    }
  }
  :hover {
    color: #212134;
  }
  :hover::after {
    transform: scaleX(1);

    transform-origin: bottom left;
    @media screen and (max-width: 550px) {
    }
  }

  @media screen and (max-width: 550px) {
    padding-bottom: 0.1rem;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em 2em 0em 2em;
  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

export const LoginInputContainer = styled.div`
  width: 100%;
  margin: 5px;
  ${LabelText} {
    padding: 0px;
    margin: 0px;
  }
`;

export const SignUpInputContainer = styled.div`
  width: 100%;
  ${LabelText} {
    padding: 0px;
    margin: 0px;
  }
`;

export const ForgotPasswordContainer = styled.div`
  padding: 0em 2em 0em 0em;
  ${LinkText} {
    text-align: right;
    /* padding-bottom: 2em; */
  }
`;

export const Navigate = styled(Link)`
  text-decoration: none;
  color: #0c75af;
  :hover {
    color: #0c75af;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  /* padding-bottom: 2em; */
  button {
    width: 85%;
    height: 2em;
    color: #ffffff;
    border: none;
    background: #1cb697;
    border-radius: 4px;

    :hover {
    }
  }
  ${FormLinkHeader} {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const InfoSpace = styled.div`
  padding: 0em 2em 2em 2em;
  position: relative;
  font-family: var(--font-family-reg);
  font-size: 12px;
  color: #666687;
  line-height: 21px;
  span {
  }
`;

export const BottomContainer = styled.div`
  /* padding-top: 2em; */
  > div {
    margin-bottom: -22px;
  }
  img {
    margin-right: 5px;
  }
  @media only screen and (max-width: 961px) {
    width: 100%;
    height: auto;
    padding: 1em;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0em 2em 0em 2em;
  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

export const Row = styled.div`
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const Column = styled.div`
  float: left;
  width: 50%;
  padding: 0px 15px 0px 0px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const RadioColumn = styled.div`
  float: left;
  width: 50%;
  padding-bottom: 0px;
  font-family: var(--font-family-reg);
  font-size: 14px;
  color: #32324d;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 5px;
  }

  label input {
    position: absolute;
    left: -9999px;
  }

  label input:checked + span {
    background-color: #d9d8ff;
    white-space: nowrap;
    border: 1px solid #4945ff;
  }

  label input:checked + span:before {
    border: 1.5px solid white;
    box-shadow: 0 0 0 1px #4945ff, inset 0 0 0 0.5em #4945ff;
  }

  label span {
    white-space: nowrap;
    width: 150px;
    height: 30px;
    border: 1px solid #cfd7df;
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }

  label span:hover {
    background-color: #d6d6e5;
  }

  label span:before {
    border: 1px solid #cfd7df;
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    /* box-shadow: 0 0 0 0.125em #00005c; */
  }
`;

export const StyledInput = styled(Input)`
  font-family: var(--font-family-reg);
  border: 0px solid #dcdce4;
  border-bottom-width: 1px;
  display: block;
  width: 100%;
  color: black;
  box-sizing: border-box;
  padding: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  box-shadow: none;

  :active {
    outline: 0;
    color: black;
  }
  :hover {
    border: 0px solid #dcdce4;
    border-bottom-width: 1px;
    display: block;
    width: 100%;
    color: black;
    box-sizing: border-box;
    padding: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 5px;
    outline: 0;
  }
  :focus {
    ::after {
      outline: 0;
    }
    border: 0px solid #dcdce4;
    border-bottom-width: 1px;
    color: black;
    box-shadow: none;
  }
  ::after {
    outline: 0;
    color: black;
  }

  ::placeholder {
    margin: 0px;
    display: inline-block;
    padding: 0px;
    color: #a5a5ba;
    font-family: var(--font-family-med);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const StyledInputPassword = styled(Input.Password)`
  font-family: var(--font-family-reg);
  border: 0px solid #dcdce4;
  border-bottom-width: 1px;

  width: 100%;
  color: black;
  box-sizing: border-box;
  padding: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  box-shadow: none;

  :active {
    outline: 0;
    color: black;
  }

  :focus {
    ::after {
      outline: 0;
    }
    border: 0px solid #dcdce4;
    border-bottom-width: 1px;
    color: black;
    box-shadow: none;
  }
  ::after {
    outline: 0;
    color: black;
  }

  ::placeholder {
    margin: 0px;
    display: inline-block;
    padding: 0px;
    color: #a5a5ba;
    font-family: var(--font-family-med);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .ant-input:placeholder-shown {
    font-family: var(--font-family-med);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-color: none;
  }
`;
