import React from "react";
import "../login/components/login.css";
import LeftAuth from "../../common/sharedComponents/LeftScreen";
import SignupRightScreen from "./components/signupRightScreen";


const SignUp = () => {
  
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div style={{ position: "absolute" }}>
          <LeftAuth />
        </div>
        <div style={{ position: "absolute" }}>
          <SignupRightScreen />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
