import {
  Button,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { approveInvoice, getInvoiceDetails } from "../redux/adminInvoicesSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { history } from "../../../app/history";
import moment from "moment";
import { ApproveButton } from "../../vendorRegistration/components/ViewvendorDetails";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import LocalStorage from "../../../utilities/localStorage";

function AdminInvoicesViewContent() {
  const { invoice_details } = useSelector((state) => state.adminInvoices);
  const [open, setOpen] = React.useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const role = LocalStorage.getItem("tata_login_user").roles;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (invoice_id) => {
    const payload = { invoice_id: invoice_id, accept_invoice: true };
    dispatch(approveInvoice(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        history.push("/app/invoices-list");
      }
    });
  };

  const invoice_id = params.invoiceid;
  const viewOrderDetails = (invoice_id) => {
    dispatch(getInvoiceDetails(invoice_id));
  };

  useEffect(() => {
    viewOrderDetails(invoice_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            Invoice#
            {invoice_details.invoice_number
              ? invoice_details.invoice_number
              : "Not Applicable"}
          </Grid>

          {invoice_details?.status === "Pending" &&
          (role[0] === "user" || role[0] === "officer") ? (
            <Grid item xs={6}>
              <ApproveButton
                onClick={handleClickOpen}
                variant="outlined"
                style={{
                  height: "40px",
                  width: "150px",
                  float: "right",
                }}
              >
                Send for Review
              </ApproveButton>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "150px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label={invoice_details.status}
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label"> ORDER NUMBER</Typography>
              <VendorCardLabel>
                {invoice_details?.Order?.order_number
                  ? invoice_details?.Order?.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE NUMBER</Typography>
              <VendorCardLabel>
                {invoice_details.invoice_number
                  ? invoice_details.invoice_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE DATE</Typography>
              <VendorCardLabel>
                {invoice_details.invoice_date
                  ? moment(invoice_details.invoice_date).format("DD MMM YY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">INVOICE AMOUNT</Typography>
              <VendorCardLabel>
                {invoice_details.invoice_amount
                  ? invoice_details.invoice_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> GST AMOUNT</Typography>
              <VendorCardLabel>
                {invoice_details.GST_amount
                  ? invoice_details.GST_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">GSTIN</Typography>
              <VendorCardLabel>
                {invoice_details.GSTIN
                  ? invoice_details.GSTIN
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOCATION</Typography>
              <VendorCardLabel>
                {invoice_details?.location
                  ? invoice_details?.location
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> BANK</Typography>
              <VendorCardLabel>
                {invoice_details?.Bank_information?.bank_name
                  ? invoice_details?.Bank_information?.bank_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">BANK A/C number</Typography>
              <VendorCardLabel>
                {invoice_details.Bank_information?.account_number
                  ? invoice_details?.Bank_information?.account_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">Branch</Typography>
              <VendorCardLabel>
                {invoice_details.Bank_information?.branch_name
                  ? invoice_details?.Bank_information?.branch_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>

          <br />
          <h5>Documents</h5>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Invoice Document</Typography>
              <VendorCardLabel>
                <CustomDocumentLink
                  href={invoice_details?.Invoice_document?.invoice_document}
                  target="_blank"
                >
                  <InsertDriveFileOutlinedIcon
                    style={{
                      marginTop: "-1px",
                      marginRight: "3px",
                      height: "12px",
                      width: "12px",
                    }}
                  />
                  Invoice document
                </CustomDocumentLink>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {invoice_details?.Invoice_document?.supporting_documents !==
                undefined &&
              invoice_details?.Invoice_document?.supporting_documents !==
                null &&
              invoice_details?.Invoice_document?.supporting_documents.length >
                0 ? (
                invoice_details?.Invoice_document?.supporting_documents.map(
                  (item, index) => (
                    <VendorCardLabel key={index}>
                      <CustomDocumentLink href={item} target="_blank">
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </VendorCardLabel>
                  )
                )
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send this invoice for review?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={(e) => handleApprove(invoice_id)}
            autoFocus
            size="small"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminInvoicesViewContent;

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
