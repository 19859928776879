import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  addFinance,
  assignRole,
  getList,
  getOfficerList,
  inActive_Acting_SA,
} from "./AdminAuthorotoesAPI";

const initialState = {
  loading: false,
  TableViewData: [],
  getOfficerList: [],
};

const actions = {
  ADDFINANCEGLOBLE: "addFinanceGloble/ADDFINANCEGLOBLE",
  TABLEVIEWDATA: "tableViewData/TABLEVIEWDATA",
  OFFICERLIST: "officerList/OFFICERLIST",
  ASSIGNACTINGROLE: "assignActingRole/ASSIGNACTINGROLE",
  INACTIVEUSER: "inactiveUser/INACTIVEUSER",
};

export const addFinanceGloble = createAsyncThunk(
  actions.ADDFINANCEGLOBLE,
  async (payload) => {
    const response = await addFinance(payload);
    return response;
  }
);
export const getTableData = createAsyncThunk(
  actions.TABLEVIEWDATA,
  async (payload) => {
    const response = await getList(payload);
    return response;
  }
);
export const officerList = createAsyncThunk(
  actions.OFFICERLIST,
  async (payload) => {
    const response = await getOfficerList(payload);
    return response;
  }
);

export const assignSuperAdminRole = createAsyncThunk(
  actions.ASSIGNACTINGROLE,
  async (payload) => {
    const response = await assignRole(payload);
    return response;
  }
);

export const inActiveActingSA = createAsyncThunk(
  actions.INACTIVEUSER,
  async (payload) => {
    const response = await inActive_Acting_SA(payload);
    return response;
  }
);

export const AdminAuthoritiesSlice = createSlice({
  name: "adminuathorities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addFinanceGloble.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFinanceGloble.fulfilled, (state, action) => {
        const {
          message: msg,

          success,
        } = action.payload;

        state.loading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addFinanceGloble.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getTableData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        const { message: msg, success, data } = action.payload;
        state.loading = false;
        if (success) {
          state.TableViewData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getTableData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(officerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(officerList.fulfilled, (state, action) => {
        const { message: msg, success, data } = action.payload;
        state.loading = false;
        if (success) {
          state.getOfficerList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(officerList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(assignSuperAdminRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignSuperAdminRole.fulfilled, (state, action) => {
        const { message: msg, success } = action.payload;

        state.loading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(assignSuperAdminRole.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(inActiveActingSA.pending, (state) => {
        state.loading = true;
      })
      .addCase(inActiveActingSA.fulfilled, (state, action) => {
        const {
          message: msg,

          success,
        } = action.payload;

        state.loading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(inActiveActingSA.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const adminAuthoritiesActions = AdminAuthoritiesSlice.actions;
export const { resetdashBoardDetails } = AdminAuthoritiesSlice.actions;

export default AdminAuthoritiesSlice.reducer;
