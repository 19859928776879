import React from "react";

import LeftAuth from "../../../../common/sharedComponents/LeftScreen";
import IndianSignupPanRight from "./IndianSignupPanRight";

function IndianSignupPAN() {
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <LeftAuth />
        <IndianSignupPanRight />
      </div>
    </div>
  );
}

export default IndianSignupPAN;
