import { Form } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import {
  FormLinkHeader,
  LabelText,
} from "../../../../assets/fonts/typography.style";

import {
  ButtonContainer,
  Column,
  Row,
  SignUpContainer,
  StyledInput,
  SignUpInputContainer,
} from "../../../../common/sharedComponents/styles/authRight.style";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { token } from "../../redux/signup/signupSlice";
import { tenant } from "../../../login/redux/loginSlice";
import { domainName } from "../../../../utilities/helper";
import LocalStorage from "../../../../utilities/localStorage";

function NonIndianSignup({ loading, onSubmit }) {
  const [client, setClient] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const search = useLocation().search;

  const inviteToken = new URLSearchParams(search).get("token");
  const inviteEmail = new URLSearchParams(search).get("email");
  const validateCompanyName = (_, value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const gstregex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (panRegex.test(value) || gstregex.test(value)) {
      return Promise.reject("Company name cannot be in PAN or GST format");
    }
    return Promise.resolve();
  };
  const onFinish = (values) => {
    const {
      contact_person_email,
      first_name,
      last_name,
      company_name,
      password,
    } = values;

    let payload = {
      contact_person_email,
      first_name,
      last_name,
      password,
      company_name,
      is_domestic: false,
      token: inviteToken,
      tokenEmail: inviteEmail,
      tenant_id: client?.id,
    };

    onSubmit(payload);
  };
  const findDomain = () => {
    if (inviteToken) {
      dispatch(tenant({ domain_url: domainName })).then((action) => {
        if (action.payload.success) {
          setClient(action.payload.data);
        }
      });
    } else {
      setClient(LocalStorage.getItem("client"));
    }
  };
  useEffect(() => {
    dispatch(token({ email: inviteEmail, token: inviteToken })).then(
      (response) => {
        if (response.payload.success) {
          findDomain();
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} onFinish={onFinish}>
      <FormTitleContainer>Basic Information</FormTitleContainer>
      <SignUpContainer>
        <SignUpInputContainer>
          <Row>
            <Column>
              <LabelText>First name</LabelText>
              <FormItem
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: `Name is a required field`,
                  },
                ]}
              >
                <StyledInput
                  style={{ paddingLeft: "0" }}
                  type="firstName"
                  placeholder="Enter first name"
                />
              </FormItem>
            </Column>
            <Column>
              <LabelText>Last name</LabelText>
              <FormItem
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: `Name is a required field`,
                  },
                ]}
              >
                <StyledInput
                  style={{ paddingLeft: "0" }}
                  type="last_name"
                  placeholder="Enter last name"
                />
              </FormItem>
            </Column>
          </Row>
        </SignUpInputContainer>
        <SignUpInputContainer>
          <LabelText>Company name</LabelText>
          <FormItem
            name="company_name"
            rules={[
              {
                required: true,
                message: `Company Name is a required field`,
              },
              { validator: validateCompanyName },
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="company_name"
              placeholder="Enter company name"
            />
          </FormItem>
        </SignUpInputContainer>
        <SignUpInputContainer>
          <LabelText>Email</LabelText>
          <FormItem
            name="contact_person_email"
            rules={[
              {
                required: true,
                message: `Email is a required field`,
              },
            ]}
          >
            <StyledInput
              style={{ paddingLeft: "0" }}
              type="company_email"
              placeholder="Enter email"
            />
          </FormItem>
        </SignUpInputContainer>
      </SignUpContainer>

      <ButtonContainer>
        <FormLinkHeader>
          <button loading={loading} type="submit">
            Sign up
          </button>
        </FormLinkHeader>
      </ButtonContainer>
    </Form>
  );
}

export default NonIndianSignup;

export const FormTitleContainer = styled.div`
  text-align: left;
  padding: 1em 2em 1em 2em;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  color: #212134;
`;
export const IconSpan = styled.span`
  > img {
    height: 11px;
    margin: -1px 1px 1px 1px;
  }
`;
