/* eslint-disable react-hooks/rules-of-hooks */
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CardContent, Grid, Typography } from "@mui/material";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesdetails } from "../redux/companySlice";

import moment from "moment";
import LocalStorage from "../../../utilities/localStorage";
function CompanyViewContent() {
  const params = useParams();
  const ClientId = LocalStorage.getItem("client")?.id;
  const { companyDetails } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const viewCompanyDetails = (company_id) => {
    dispatch(getCompaniesdetails(company_id));
  };

  useEffect(() => {
    viewCompanyDetails(params.company);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Company#{params.company}
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">COMPANY NAME</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.company_name
                  ? companyDetails[0]?.company_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LEGAL NAME</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.legal_name
                  ? companyDetails[0]?.legal_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">COMPANY EMAIL</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.company_email
                  ? companyDetails[0]?.company_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">CONTACT EMAIL</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.contact_person_email
                  ? companyDetails[0]?.contact_person_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            {ClientId === 2 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">Company Code</Typography>
                  <VendorCardLabel>
                    {companyDetails[0]?.company_code
                      ? companyDetails[0]?.company_code
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="label">Company Location</Typography>
                  <VendorCardLabel>
                    {companyDetails[0]?.company_location
                      ? companyDetails[0]?.company_location
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={6} md={6}>
              <Typography className="label">PAN NUMBER</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.permanent_account_number
                  ? companyDetails[0]?.permanent_account_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DATE</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.createdAt
                  ? moment(companyDetails[0]?.createdAt).format("DD MMM YYYY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">TIME</Typography>
              <VendorCardLabel>
                {companyDetails[0]?.createdAt
                  ? moment(companyDetails[0]?.createdAt).format("hh:mm:ss a")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default CompanyViewContent;
