import React from "react";

import rectangle from "../../../assets/Rectangle.png";
import {
  AuthFormContainer,
  BottomContainer,
  ButtonContainer,
  ForgotPasswordContainer,
  InfoSpace,
  LoginInputContainer,
  LoginContainer,
  Navigate,
  StyledInput,
} from "../../../common/sharedComponents/styles/authRight.style";
import {
  FormLinkHeader,
  LabelText,
  LinkText,
} from "../../../assets/fonts/typography.style";
import "../../login/components/login.css";

import info from "../../../assets/icon.png";
import { Form } from "antd";
import { NavLink } from "react-router-dom";
import FormItem from "../../../common/sharedComponents/FormItem";
import LocalStorage from "../../../utilities/localStorage";

function RightForgotPasswordScreen({ loading, onSubmit }) {
  const [form] = Form.useForm();
  const tenant_id = LocalStorage.getItem("client")?.id;

  const onFinish = (values) => {
    const { email } = values;

    let payload = {
      email,
      tenant_id,
    };

    onSubmit(payload);
  };

  return (
    <div className="col-md-5 p-0 h-md-100 h-100 w-100 d-flex justify-content-end align-items-end">
      {/* <div className="position-absolute top-0 end-0">
        <img src={ellipse} alt="ellipse" />
      </div> */}
      <div className="position-fixed bottom-0 end-0 ">
        <img src={rectangle} alt="rectangle" />
      </div>
      <div
        className="d-md-flex align-items-center h-md-100 col-md-5 p-5 justify-content-center"
        style={{ height: "100vh" }}
      >
        <AuthFormContainer style={{ height: "90vh" }}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <FormLinkHeader>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/forgot-password"
                >
                  Forgot Password?
                </NavLink>
              </FormLinkHeader>
            </div>
          </div>
          <Form form={form} onFinish={onFinish}>
            <LoginContainer>
              <LoginInputContainer>
                <LabelText>Email ID</LabelText>
                <FormItem
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: `Email is a required field`,
                    },
                  ]}
                >
                  <StyledInput
                    style={{ paddingLeft: "0" }}
                    type="email"
                    placeholder="Enter email"
                  />
                </FormItem>
              </LoginInputContainer>
            </LoginContainer>

            <ButtonContainer>
              <FormLinkHeader>
                <button loading={loading} type="submit">
                  Reset Password
                </button>
              </FormLinkHeader>
            </ButtonContainer>

            <ForgotPasswordContainer>
              <LinkText style={{ textAlign: "center" }}>
                <Navigate to="/login"> Back to Login</Navigate>
              </LinkText>
            </ForgotPasswordContainer>

            <BottomContainer>
              <div>
                <img src={info} alt="info" />
              </div>

              <InfoSpace>
                We sent you an email with the link to reset your password.
              </InfoSpace>
            </BottomContainer>
          </Form>
        </AuthFormContainer>
      </div>
    </div>
  );
}

export default RightForgotPasswordScreen;
