import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectSpan,
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import LoadingButton from "@mui/lab/LoadingButton";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { history } from "../../../app/history";
import {
  RolesLists,
  createUser,
  getCompanyList,
} from "../redux/companiesUserSlice";
import { capitalizeString } from "../../../utilities/helper";

function CompaniesUserContent() {
  const dispatch = useDispatch();
  const { companyList, roleList } = useSelector((state) => state.companiesUser);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Vendordetails = () => {
    dispatch(getCompanyList());
  };
  useEffect(() => {
    Vendordetails();
    // eslint-disable-next-line
  }, []);

  const handleSave = (data) => {
    setLoading(true);
    var userDetails = {
      company_id: data.company_id,
      // permanent_account_number: data.permanent_account_number,
      first_name: data.first_name,
      last_name: data.last_name,
      role_id: data.role_id,
      contact_person_email: data.contact_person_email,
    };
    dispatch(createUser(userDetails)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/users");
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Add User in Companies
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl
                  variant="standard"
                  style={{ width: "100%" }}
                  error={Boolean(errors?.company_id)}
                >
                  <VendorCardLabel>
                    Select Company<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="company_id"
                    name="company_id"
                    error={Boolean(errors?.company_id)}
                    {...register("company_id", {
                      required: "Please select company",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select company
                      </span>
                    </MenuItem>
                    {companyList.map((item, index) => (
                      <MenuItem
                        value={item.id}
                        onClick={() => dispatch(RolesLists(item.id))}
                        key={index}
                      >
                        <SelectSpan>{item.company_name}</SelectSpan>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.company_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Select User Role<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="role_id"
                    name="role_id"
                    error={Boolean(errors?.role_id)}
                    {...register("role_id", {
                      required: "Please select user role",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select user role
                      </span>
                    </MenuItem>
                    {roleList &&
                      roleList.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          <SelectSpan>
                            {capitalizeString(item.role_name)}
                          </SelectSpan>
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error>
                    {errors.role_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  First Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter first Name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="first_name"
                  {...register("first_name", {
                    required: "Please enter first name",
                  })}
                  error={Boolean(errors?.first_name)}
                  helperText={errors?.first_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Last Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter Last Name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="last_name"
                  {...register("last_name", {
                    required: "Please enter last name",
                  })}
                  error={Boolean(errors?.last_name)}
                  helperText={errors?.last_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Contact Email<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter conatct email"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="contact_person_email"
                  {...register("contact_person_email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "enter valid email",
                    },
                  })}
                  error={Boolean(errors?.contact_person_email)}
                  helperText={errors?.contact_person_email?.message}
                />
              </Grid>
              {/* <Grid item md={6}>
                <VendorCardLabel>
                  PAN Number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter PAN Number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="permanent_account_number"
                  {...register("permanent_account_number", {
                    required: "Please enter PAN number",
                  })}
                  error={Boolean(errors?.permanent_account_number)}
                  helperText={errors?.permanent_account_number?.message}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add User
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default CompaniesUserContent;
