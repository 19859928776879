import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { Box, Card, CircularProgress, Pagination, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useSelector } from "react-redux";

import moment from "moment";
import { EmptyContainer } from "../../../../utilities/helper";

function PendingContent({ setPage, setPer_page, page, per_page }) {
  const { VendorData, loading, pagination } = useSelector(
    (state) => state.pendinglist
  );

  // // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - VendorData.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Status</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Awaited By</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {VendorData.length !== 0 ? (
                VendorData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/app/vendor-registration-view/${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {" "}
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.vendor_code !== undefined &&
                        row.vendor_code !== null ? (
                          `${row.vendor_code}`
                        ) : (
                          <Chip
                            label={row.status.toUpperCase()}
                            color={
                              row.status === "Registered"
                                ? "success"
                                : "warning"
                            }
                            size="small"
                            style={{ fontSize: "9px" }}
                          />
                        )}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.status ? `${row.status}` : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.approvalName
                          ? `${row.approvalName}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There are no data to Display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
}

export default PendingContent;

// for Officer
export const PendingContentForOfficer = ({
  setPage,
  setPer_page,
  page,
  per_page,
}) => {
  const {
    pending,
    pending_pagination: pagination,
    loading,
  } = useSelector((state) => state.pendinglist);

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Status</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Awaited By</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {pending.length !== 0 ? (
                pending.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/vendor-registration-view/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {" "}
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.vendor_code !== undefined &&
                        row.vendor_code !== null ? (
                          `${row.vendor_code}`
                        ) : (
                          <Chip
                            label={row.status.toUpperCase()}
                            color={
                              row.status === "Registered"
                                ? "warning"
                                : "success"
                            }
                            size="small"
                            style={{ fontSize: "9px" }}
                          />
                        )}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.status ? `${row.status}` : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.approvalName
                          ? `${row.approvalName}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There are no data to Display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        {pending.length !== 0 ? (
          <Grid
            container
            className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
            sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
          >
            <Grid className="d-flex align-items-center gap-2">
              <span>Rows per page: </span>
              <select
                value={per_page}
                onChange={(e) => setPer_page(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </Grid>
            <Pagination
              sx={{
                width: "max-content",
              }}
              color="primary"
              variant="text"
              onChange={(_, newValue) => setPage(newValue)}
              count={pagination?.total_page}
              page={page}
              shape="rounded"
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Grid>
        ) : (
          ""
        )}
      </CustomTableContainer>
    </>
  );
};
