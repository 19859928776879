import apiClient from "../../../utilities/apiClient";

export const addFinance = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_finance_globle}`, payload, true);
};

export const getList = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_list}`, payload, true);
};

export const getOfficerList = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_officer_list}`, payload, true);
};
export const assignRole = (payload) => {
  return apiClient.post(`${apiClient.Urls.assign_acting_role}`, payload, true);
};
export const inActive_Acting_SA = (payload) => {
  return apiClient.put(`${apiClient.Urls.inactive_acting_role}`, payload, true);
};
