import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  approvedList,
  getAllVendorForAdmin,
  pendingList,
  returnedList,
  sendToSAP,
  upload_reg,
  fetchVendorCodewithCompany,
  vendorListCount,
} from "./adminTableAPI";

const initialState = {
  loading: false,
  token: "",
  pending: [],
  approved: [],
  returned: [],
  VendorData: [],
  SAP: [],
  pagination: {},
  VendorCodeWithCompanyName: [],
  pending_pagination: {},
  approved_pagination: {},
  returned_pagination: {},
  vendorCount: {},
};

const actions = {
  GETPENDINGLIST: "getPendingList/GETPENDINGLIST",
  GETAPPROVEDLIST: "getApprovedList/GETAPPROVEDLIST",
  GETRETURNEDLIST: "getReturnedList/GETRETURNEDLIST",
  REG_VENDOR: "reg_vendor/REG_VENDOR",
  GETALLVENDOR: "getVendor/GETALLVENDOR",
  PUSHTOSAP: "pushToSAP/PUSHTOSAP",
  VENDORCODEWITHCOMPANYNAME:
    "vendorcodeWithCompanyName/VENDORCODEWITHCOMPANYNAME",
  VENDOR_COUNT: "vendorCount/VENDOR_COUNT",
};

export const getPendingList = createAsyncThunk(
  actions.GETPENDINGLIST,
  async (payload) => {
    const response = await pendingList(
      payload?.page,
      payload?.per_page,
      payload?.search
    );
    return response;
  }
);

export const getApprovedList = createAsyncThunk(
  actions.GETAPPROVEDLIST,
  async (payload) => {
    const response = await approvedList(
      payload?.page,
      payload?.per_page,
      payload?.search
    );
    return response;
  }
);

export const getReturnedList = createAsyncThunk(
  actions.GETRETURNEDLIST,
  async (payload) => {
    const response = await returnedList(
      payload?.page,
      payload?.per_page,
      payload?.search
    );
    return response;
  }
);
export const vendor_reg_uplaod_excel = createAsyncThunk(
  actions.REG_VENDOR,
  async (payload) => {
    const response = await upload_reg(payload);
    return response;
  }
);
export const pushSAP = createAsyncThunk(actions.PUSHTOSAP, async (payload) => {
  const response = await sendToSAP(payload);
  return response;
});
export const fetchVendorCode = createAsyncThunk(
  actions.VENDORCODEWITHCOMPANYNAME,
  async (payload) => {
    const response = await fetchVendorCodewithCompany(payload);
    return response;
  }
);
export const vendorCount = createAsyncThunk(
  actions.VENDOR_COUNT,
  async (payload) => {
    const response = await vendorListCount(payload);
    return response;
  }
);
export const getALLVendorAdmin = createAsyncThunk(
  actions.GETALLVENDOR,
  async (payload) => {
    const response = await getAllVendorForAdmin(
      payload?.status,
      payload?.registerFilter,
      payload?.page,
      payload?.per_page,
      payload?.search
    );
    return response;
  }
);
export const getPendingSlice = createSlice({
  name: "pendinglist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.pending = data;
        state.pending_pagination = pagination;
        state.loading = false;
      })
      .addCase(getPendingList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getApprovedList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApprovedList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.approved = data;
        state.approved_pagination = pagination;
        state.loading = false;
      })
      .addCase(getApprovedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getReturnedList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReturnedList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        /* state.loading = false; */

        state.returned = data;
        state.returned_pagination = pagination;
        state.loading = false;
      })
      .addCase(getReturnedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(vendor_reg_uplaod_excel.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendor_reg_uplaod_excel.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        if (action.payload?.success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(vendor_reg_uplaod_excel.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getALLVendorAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getALLVendorAdmin.fulfilled, (state, action) => {
        const { data, success, pagination } = action.payload;
        console.log("getALLVendorAdmin data: ", data);
        if (success) {
          state.VendorData = data;
          state.pagination = pagination;
        }
        state.loading = false;
      })
      .addCase(getALLVendorAdmin.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(pushSAP.pending, (state) => {
        state.loading = true;
      })
      .addCase(pushSAP.fulfilled, (state, action) => {
        // const { data, success } = action.payload;
        const { success, message: msg } = action.payload;
        state.loading = false;

        if (success) {
          message.success("vendor push in SAP.");
          // if (data[0].MESSAGE === "Vendor Master is uniq for -") {
          //   state.SAP = data;
          //   state.loading = false;
          // } else {
          //   message.info("vendor already push to SAP");
          // }
        } else {
          message.error(msg);
        }
      })
      .addCase(pushSAP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(fetchVendorCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVendorCode.fulfilled, (state, action) => {
        const { data, success } = action.payload;
        if (success) {
          state.VendorCodeWithCompanyName = data;
        }
        state.loading = false;
      })
      .addCase(fetchVendorCode.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(vendorCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorCount.fulfilled, (state, action) => {
        const { data, success } = action.payload;
        if (success) {
          state.vendorCount = data;
        }
        state.loading = false;
      })
      .addCase(vendorCount.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const getPendingActions = getPendingSlice.actions;
export const { resetPending } = getPendingSlice.actions;

export default getPendingSlice.reducer;
