import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledCard } from "../../vendorRegistration/components/StepMain";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getBanks } from "../redux/bankDetailsSlice";
import empty from "../../../assets/illo.png";
import { getLoggedInUser } from "../../../utilities/helper";

const Empty = () => (
  <>
    <Parent>
      <Child>
        <img src={empty} alt="empty" />
      </Child>
    </Parent>
    <div style={{ textAlign: "center", padding: "15px" }}>
      <DisplayText> There are no bank accounts to display</DisplayText>
    </div>
    <div style={{ textAlign: "center", marginLeft: "5px" }}>
      <ButtonLink to="/app/bank-details/add-bank">+ Add Bank</ButtonLink>
    </div>
  </>
);

export const BankCard = ({ item }) => {
  const {
    bank_name,
    city,
    branch_name,
    account_name,
    account_number,
    is_primary_account,
    currency,
  } = item;
  const handleMoreVertClick = () => {
    // Perform your desired action here
    alert("Performing action after clicking MoreVertIcon");
  };
  return (
    <StyledCard sx={{ /*  maxWidth: 400, */ border: "1px solid #EAEAEF" }}>
      <CardHeader
        action={


          getLoggedInUser().roles[0] !== "super admin" ||
          getLoggedInUser().roles[0] !== "finance" ? (

            <IconButton aria-label="settings">
              <MoreVertIcon onClick={handleMoreVertClick} />
            </IconButton>
          ) : (
            ""
          )
        }
        title={
          <BankName style={{ textAlign: "left", padding: "0", margin: "0" }}>
            {bank_name}
            {is_primary_account ? (
              <span
                style={{
                  fontSize: "14px",
                  borderRadius: "25px",
                  background: "#D55EE1",
                  padding: "1px 20px",
                  width: "93px",
                  height: "22px",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Primary
              </span>
            ) : (
              ""
            )}
          </BankName>
        }
      />
      <hr
        style={{
          padding: "0",
          margin: "0",
          border: "1px solid #DCDCE4",
          backgroundColor: "#DCDCE4",
          height: "0.1px",
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LeftDetailsBankCard>City:</LeftDetailsBankCard>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <RightDetailsBankCard>{city}</RightDetailsBankCard>
          </Grid>
          <Grid item xs={6}>
            <LeftDetailsBankCard>Branch:</LeftDetailsBankCard>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <RightDetailsBankCard>{branch_name}</RightDetailsBankCard>
          </Grid>
          <Grid item xs={6}>
            <LeftDetailsBankCard>A/C name:</LeftDetailsBankCard>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <RightDetailsBankCard>{account_name}</RightDetailsBankCard>
          </Grid>
          <Grid item xs={6}>
            <LeftDetailsBankCard>A/C number:</LeftDetailsBankCard>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <RightDetailsBankCard>{account_number}</RightDetailsBankCard>
          </Grid>
          <Grid item xs={6}>
            <LeftDetailsBankCard>Currency:</LeftDetailsBankCard>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <RightDetailsBankCard style={{ textTransform: "uppercase" }}>
              {currency}
            </RightDetailsBankCard>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

function BankAccountsContent() {
  const { states } = useSelector((state) => state.bankdetails);
  const dispatch = useDispatch();

  const bankinfo = (payload) => {
    dispatch(getBanks(payload));
  };
  useEffect(() => {
    bankinfo();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {states.length === 0 ? (
        <Empty />
      ) : (
        <Grid container spacing={3}>
          {states.map((item, index) => (
            <Grid key={index} item xs={12} md={5}>
              <BankCard item={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default BankAccountsContent;

export const AddButton = styled(Link)`
  float: right;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  position: relative;
  background: #4dc4aa;
  box-shadow: 0px 0px 25px 5px rgba(51, 51, 51, 0.2);
  ::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 2px;
    margin-top: -1px;
    top: 50%;
    left: 13px;
    right: 13px;
    z-index: 9;
  }
  ::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 2px;
    margin-left: -1px;
    left: 50%;
    top: 13px;
    bottom: 13px;
    z-index: 9;
  }
`;
export const BankName = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Neutral/900 */

  color: #212134;
`;

export const LeftDetailsBankCard = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/600 */

  color: #666687;
`;

export const DisplayText = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 16x;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/600 */

  color: #666687;
`;

export const RightDetailsBankCard = styled.div`
  font-family: var(--font-family-med);

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/900 */

  color: #212134;
  /* identical to box height, or 150% */

  /* Neutral/600 */
`;

export const Parent = styled.div`
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Child = styled.div`
  > img {
    margin-bottom: "15px";
  }
`;

export const ButtonLink = styled(Link)`
  border: 1px solid var(--button-color);
  background-color: #ffff;
  color: black;
  border-radius: 4px;
  font-size: 14px;

  height: 36px;
  padding: 7px 18px 7px 18px;
  font-weight: 700;
  font-family: var(--font-family-reg);
  text-decoration: none;
  :hover {
    color: black;
  }
`;
