import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  approve_invoice,
  get_AdminInvoices,
  view_InvoiceDetails,
} from "./adminInvoicesAPI";

const initialState = {
  loading: false,
  adminInvoicesList: [],
  invoice_details: [],
};
const actions = {
  GETADMININVOICES: "getAdminInvoices/GETADMININVOICES",
  VIEWINVOICEDETAILS: "getInvoiceDetails/VIEWINVOICEDETAILS",
  APPROVEINVOICE: "approveInvoice/APPROVEINVOICE",
};

export const getAdminInvoices = createAsyncThunk(
  actions.GETADMININVOICES,
  async (payload) => {
    const response = await get_AdminInvoices(payload);
    return response;
  }
);

export const getInvoiceDetails = createAsyncThunk(
  actions.VIEWINVOICEDETAILS,
  async (payload) => {
    const response = await view_InvoiceDetails(payload);
    return response;
  }
);

export const approveInvoice = createAsyncThunk(
  actions.APPROVEINVOICE,
  async (payload) => {
    const response = await approve_invoice(payload);
    return response;
  }
);
export const adminInvoicesSlice = createSlice({
  name: "adminInvoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminInvoices.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminInvoicesList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getInvoiceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.invoice_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getInvoiceDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveInvoice.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminInvoicesActions = adminInvoicesSlice.actions;
export const { resetadminInvoicesDetails } = adminInvoicesSlice.actions;

export default adminInvoicesSlice.reducer;
