import React from "react";
import styled from "styled-components";
import IndianSignUpStepper from "../steps/IndianSignUpStepper";

function IndianSignup(visible) {
  return <IndianSignUpStepper visible={visible} />;
}

export default IndianSignup;

export const FormTitleContainer = styled.div`
  text-align: left;
  padding: 1em 2em 1em 2em;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  color: #212134;
`;
export const IconSpan = styled.span`
  > img {
    height: 11px;
    margin: -1px 1px 1px 1px;
  }
`;
