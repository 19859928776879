import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { approve_order, get_orders, view_OrderDetails } from "./userOrdersAPI";

const initialState = {
  loading: false,
  ordersList: [],
  order_details: [],
};
const actions = {
  GETORDERS: "getOrders/GETORDERS",
  VIEWORDERDETAILS: "getOrderDetails/VIEWORDERDETAILS",
  APPROVEORDER: "approveOrder/APPROVEORDER",
};

export const getOrders = createAsyncThunk(
  actions.GETORDERS,
  async (payload) => {
    const response = await get_orders(payload);
    return response;
  }
);

export const getOrderDetails = createAsyncThunk(
  actions.VIEWORDERDETAILS,
  async (payload) => {
    const response = await view_OrderDetails(payload);
    return response;
  }
);

export const approveOrder = createAsyncThunk(
  actions.APPROVEORDER,
  async (payload) => {
    const response = await approve_order(payload);
    return response;
  }
);

export const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ordersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.order_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveOrder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userOrdersActions = userOrdersSlice.actions;
export const { resetuserOrdersDetails } = userOrdersSlice.actions;

export default userOrdersSlice.reducer;
