import React from "react";
import { Grid } from "@mui/material";
import { VendorCardLabel } from "../../../features/vendorRegistration/components/StepMain";
import { BankDisabledFields } from "../../../features/vendorRegistration/components/steps/cardTrial";

const MsmeComponents = ({
  msme_type,
  enterprise_name,
  enterprise_activity,
  classification_date,
  classification_year,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VendorCardLabel>Enterprise Name</VendorCardLabel>
          <BankDisabledFields>{enterprise_name || ""}</BankDisabledFields>
        </Grid>
        <Grid item xs={12} md={4}>
          <VendorCardLabel>Enterprise type</VendorCardLabel>
          <BankDisabledFields>{msme_type || ""}</BankDisabledFields>
        </Grid>
        <Grid item xs={12} md={4}>
          <VendorCardLabel>Enterprise Activity</VendorCardLabel>
          <BankDisabledFields>{enterprise_activity}</BankDisabledFields>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VendorCardLabel>Classification Date</VendorCardLabel>
          <BankDisabledFields>{classification_date}</BankDisabledFields>
        </Grid>
        <Grid item xs={12} md={4}>
          <VendorCardLabel>classification year</VendorCardLabel>
          <BankDisabledFields>{classification_year}</BankDisabledFields>
        </Grid>
      </Grid>
    </>
  );
};

export default MsmeComponents;
