import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_orders = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.order_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const view_OrderDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_order_details}/${payload}`,
    "",
    true
  );
};

export const approve_order = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.order_approve}/${payload.order_id}`,
    payload,
    true
  );
};
