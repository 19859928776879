import React, { useState, useEffect } from "react";
import { VendorInstructions, VendorListHolder } from "./vendorReg";
import info from "../../../../assets/icon.png";
import styled from "styled-components";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { styled as styled1 } from "@mui/material/styles";
import { StyledCard } from "../StepMainEdit";
import LoadingButton from "@mui/lab/LoadingButton";
import RecipeReviewCard from "./cardTrialEdit";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  bussinesslocationsupdate,
  GSTdocument,
} from "../../redux/vendorRegistrationSlice";
import { history } from "../../../../app/history";
import { message } from "antd";
import { hasDuplicates } from "../../../../utilities/helper";

function BankRegEdit({ activeStep, handleBack, vendor_details, clientId }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [expanded, setExpanded] = useState({});
  const [locations, setLocations] = useState([]);
  const [contacts, setContacts] = useState({});
  const [loading, setLoading] = useState(false);
  const [registerType, setRegisterType] = useState("");

  const handleExpandClick = (ind) => {
    setExpanded({
      ...expanded,
      [ind]: !expanded[ind],
    });
  };

  const handleSetContacts = (ind, val) => {
    setContacts({
      ...contacts,
      [ind]: val,
    });
  };

  useEffect(() => {
    if (vendor_details?.Locations) {
      setLocations(vendor_details?.Locations);
      setRegisterType(vendor_details?.gst_registration_type);
    }
    // eslint-disable-next-line
  }, [vendor_details?.gst_registration_type]);

  useEffect(() => {
    if (locations.length) {
      let selectedContacts = {};
      locations.forEach((item, index) => {
        selectedContacts = {
          ...selectedContacts,
          [index]: item.Contacts,
        };
      });
      setContacts(selectedContacts);
    }
  }, [locations]);

  const dispatch = useDispatch();

  const handleSave = () => {
    const isDuplicate = hasDuplicates(locations, [
      "contacts",
      "phone",
      "branch_email",
    ]);
    if (isDuplicate) {
      return message.error("Duplicate location");
    }
    setLoading(true);
    let data = locations.map((item, index) => {
      let modifiedContacts = (contacts[index] || []).map((con) => {
        delete con.edit;
        return con;
      });

      return {
        ...item,
        contacts: modifiedContacts,
      };
    });

    let promises = [];
    data.forEach((item) => {
      const { gst_document_image_name } = item;

      if (
        typeof gst_document_image_name === "object" &&
        gst_document_image_name !== null
      ) {
        let formData = new FormData();
        formData.append("file", gst_document_image_name);

        promises.push(dispatch(GSTdocument(formData)));
      } else {
        promises.push(
          Promise.resolve({ payload: { data: "nochange", success: true } })
        );
      }
    });

    Promise.all(promises).then((responses) => {
      if (responses.every((o) => o?.payload?.success)) {
        responses.forEach((res, ind) => {
          if (res?.payload?.data !== "nochange") {
            data[ind] = {
              ...data[ind],
              gst_document_image: res?.payload?.data?.Location || null,
              gst_document_image_name: res?.payload?.data?.file_name || null,
            };
          }
        });
        if (registerType !== "unregistered") {
          data = data.filter(
            (obj) =>
              obj.branch_email.trim() !== "" &&
              obj.phone.trim() !== "" &&
              obj.location !== "" &&
              obj.gst_document_image_name !== ""
          );
        } else {
          data = data.filter(
            (obj) =>
              obj.branch_email.trim() !== "" &&
              obj.phone.trim() !== "" &&
              obj.location !== ""
          );
        }
        if (!data.length) {
          message.info("Add at least one business location");
          setLoading(false);
          return;
        }
        dispatch(bussinesslocationsupdate({ data })).then((response) => {
          if (response?.payload?.success) {
            setLoading(false);
            history.push("/app");
          }
        });
      } else {
        setLoading(false);
        let firstErrorIndex = responses.findIndex((o) => !o?.payload?.success);

        message.error(
          `Error uploading Business location ${
            firstErrorIndex + 1
          } GST document. Submission failed`
        );
      }
    });
  };

  const handleAddLocation = () => {
    let locationsCopy = [...locations];

    locationsCopy.push({
      location_gst_id: "",
      location: "",
      phone: "",
      branch_email: "",
      fax_number: "",
      state: "",
      gst_document_image_name: "",
    });

    handleExpandClick(locationsCopy.length - 1);
    setLocations(locationsCopy);
  };

  const handleLocationDelete = (index) => {
    let locationsCopy = [...locations];
    // eslint-disable-next-line
    locationsCopy = locationsCopy.filter((_, i) => i != index);

    setLocations(locationsCopy);
  };

  const handleLocationOnchange = (e, index) => {
    const { name, value } = e.target;

    let locationsCopy = [...locations];

    let extra = {};
    if (name === "gst_document_image_name") {
      extra = {
        ...extra,
        gst_document_image: null,
      };
    }

    locationsCopy[index] = {
      ...(locationsCopy[index] || {}),
      [name]: value,
      ...extra,
    };

    setLocations(locationsCopy);
  };

  return (
    <div>
      <StyledCard>
        <CardContent>
          <VendorInstructions>
            <img src={info} alt="info" /> Instructions
          </VendorInstructions>
          <br />
          <VendorListHolder>
            <li>
              This is a API enabled form. E-mail and GSTIN data will need to be
              validated automatically before you can submit it.
            </li>
            <li>
              Click on Validate below the GSTIN field and selct the location
              from the drop down list in the location field.
            </li>
            <li style={{ paddingBottom: "5px" }}>
              Address details will be autopopulated and are not editable.
            </li>
          </VendorListHolder>
        </CardContent>
      </StyledCard>

      <form onSubmit={handleSubmit(handleSave)}>
        {locations.map((location_item, index) => (
          <RecipeReviewCard
            key={index}
            l_index={index}
            location_item={location_item}
            register={register}
            errors={errors}
            expanded={expanded[index] || false}
            handleExpandClick={handleExpandClick}
            contacts={contacts[index] || []}
            setContacts={(value) => handleSetContacts(index, value)}
            onChange={handleLocationOnchange}
            onDelete={handleLocationDelete}
            registerType={registerType}
            clientId={clientId}
          />
        ))}

        {clientId === 2 && locations.length === 1 ? (
          ""
        ) : (
          <AddBusinessCard>
            <CardContent>
              <Grid onClick={handleAddLocation}>
                <LocationDiv>+ Add business location</LocationDiv>
              </Grid>
            </CardContent>
          </AddBusinessCard>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Button
            color="inherit"
            disabled={!activeStep}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {/* <Box sx={{ flex: "1 1 auto" }} /> */}
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
}

export default BankRegEdit;

export const LocationDiv = styled.div`
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #271fe0;
  :hover {
    cursor: pointer;
  }
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorLabelHolder = styled.div`
  text-align: left;
  padding: 0 20px 20px 20px;
`;

export const StyledContainedButton = styled(Button)`
  text-transform: none;
  background-color: #dcdce4;
  margin-left: 15px;
  color: white;
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  padding: 5px 30px 5px 30px;

  :active {
    color: grey;
  }
  :hover {
    border: 1px solid var(--button-color);
    background-color: white;
    color: black;
  }
`;

export const StyledTextField = styled(TextField)`
  font-family: var(--font-family-med);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 320px;
  outline: none;
  /* Neutral/200 */
  ::placeholder {
    font-family: var(--font-family-med);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  ::after {
    border-bottom: 1px solid #dcdce4;
  }

  :active {
    border-bottom: 1px solid #dcdce4;
  }
  border-bottom: 1px solid #dcdce4;
`;

const AddBusinessCard = styled1(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;

border: 1px dashed #C0c0cf;
height: 70px;
`;
