import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminTableContent from "./components/adminTableContent";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { StyledOutlineButton } from "../vendorRegistration/components/StepMain";

import { useDispatch } from "react-redux";
import { vendor_reg_uplaod_excel } from "./redux/adminTableSlice";
import { history } from "../../app/history";
import LocalStorage from "../../utilities/localStorage";
import { Search } from "@mui/icons-material";

function AdminTable() {
  const [loading, setLoading] = React.useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [clicked, setClicked] = React.useState(false);
  const [data, setData] = React.useState("");
  const userData = LocalStorage.getItem("tata_login_user");
  const dispatch = useDispatch();
  const handleClose = () => {
    setFileOpen(false);
  };

  const handleUpload = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", data);
    dispatch(vendor_reg_uplaod_excel(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setFileOpen(false);
        history.push("/app/recomm-vcc");
      }
      setLoading(false);
      setFileOpen(false);
    });
    setLoading(false);
  };
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid container spacing={2} sx={{ display: "flex", gap: "1rem" }}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Vendors
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item sx={{ display: "flex", gap: "1rem" }}>
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>
            {userData.roles[0] === "super admin" ||
            userData.roles[0] === "finance" ? (
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    gap: 3,
                  }}
                >
                  <StyledOutlineButton
                    variant="outlined"
                    component="span"
                    style={{ borderColor: "#4059e5" }}
                  >
                    {/* <a href={excel} download style={{ textDecoration: "none" }}> */}
                    Download Excel
                    {/* </a> */}
                  </StyledOutlineButton>
                  <StyledOutlineButton
                    variant="outlined"
                    component="span"
                    onClick={() => setFileOpen(true)}
                  >
                    Upload
                  </StyledOutlineButton>
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <AdminTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
        />
      </StepperContentHolder>

      {/* add file dialog */}
      <Dialog
        open={fileOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "Bold" }}
          id="draggable-dialog-title"
        >
          Add Excel File
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>

          <DialogContentText>
            <input
              type="file"
              fullWidth
              accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => setData(e.target.files[0])}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}>
          <Button
            autoFocus
            onClick={(handleClose, () => setData(""))}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "70px",
              height: "30px",
            }}
            color="primary"
            variant="contained"
            onClick={handleUpload}
            loading={loading}
            loadingPosition="start"
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminTable;
