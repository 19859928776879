import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_vendors = (payload) => {
  return apiClient.get(`${apiClient.Urls.vendors_list}`, payload, true);
};

export const get_vendor_emails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.vendors_list}?vendor_id=${payload}`,
    "",
    true
  );
};

export const orders = (payload) => {
  return apiClient.post(`${apiClient.Urls.orders}`, payload, true, "file");
};

export const view_AdminOrderDetails = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  return apiClient.get(
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_order_view}/${payload}`
      : `${apiClient.Urls.view_admin_order_details}/${payload}`,
    "",
    true
  );
};

export const get_orders = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  return apiClient.get(
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_order_list}`
      : `${apiClient.Urls.orders_list}`,
    payload,
    true
  );
};

export const add_excel_data = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_excel_orders}`, payload, true);
};
