import {
  Box,
  CardContent,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Input,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { history } from "../../../app/history";
import LocalStorage from "../../../utilities/localStorage";
import {
  CustomButton,
  StyledCard,
  VendorCardLabel,
  VendorDisabledFields,
} from "../../vendorRegistration/components/StepMain";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import { changePassword } from "../redux/accountSettingsSlice";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function AccountSettingsContent() {
  const value = LocalStorage.getItem("tata_login_user");
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    showPassword: false,
    showCurrentPassword: false,
  });

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (payload) => {
    dispatch(changePassword(payload)).then((response) => {
      if (response?.payload?.success) {
        history.push("/app");
      }
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNext = (data) => {
    onSubmit(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <StyledCard>
          <CardContent>
            <VendorCardTitle> Change Password</VendorCardTitle>

            <br />
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>First Name</VendorCardLabel>
                  <VendorDisabledFields style={{ textTransform: "capitalize" }}>
                    <b>{value.first_name}</b>
                  </VendorDisabledFields>
                </Grid>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>Last Name</VendorCardLabel>
                  <VendorDisabledFields style={{ textTransform: "capitalize" }}>
                    <b>{value.last_name}</b>
                  </VendorDisabledFields>
                </Grid>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>Email</VendorCardLabel>
                  <VendorDisabledFields>
                    <b> {value.contact_person_email}</b>
                  </VendorDisabledFields>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>Current Password</VendorCardLabel>

                  <Input
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter current password"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="current_password"
                    {...register("current_password", {
                      required: "Please enter current password",
                    })}
                    type={values.showCurrentPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showCurrentPassword ? (
                            <VisibilityOff sx={{ height: "18px" }} />
                          ) : (
                            <Visibility sx={{ height: "18px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(errors?.current_password)}
                  />
                  <FormHelperText
                    style={{ color: "#d32f2f", margin: "0", paddingTop: "5px" }}
                  >
                    {errors.current_password?.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>New Password</VendorCardLabel>
                  <Input
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter new password"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="new_password"
                    {...register("new_password", {
                      required: "Please enter new password",
                    })}
                    type={values.showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff sx={{ height: "18px" }} />
                          ) : (
                            <Visibility sx={{ height: "18px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(errors?.new_password)}
                  />
                  <FormHelperText
                    style={{ color: "#d32f2f", margin: "0", paddingTop: "5px" }}
                  >
                    {errors.new_password?.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                  <VendorCardLabel>Confirm New Password</VendorCardLabel>
                  <Input
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Re-enter new password"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="confirm_password"
                    {...register("confirm_password", {
                      required: "Please confirm password",
                    })}
                    type={values.showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff sx={{ height: "18px" }} />
                          ) : (
                            <Visibility sx={{ height: "18px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(errors?.confirm_password)}
                  />

                  <FormHelperText
                    style={{ color: "#d32f2f", margin: "0", paddingTop: "5px" }}
                  >
                    {errors.confirm_password?.message}
                  </FormHelperText>
                </Grid>
              </Grid>
              <br />
            </Container>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <CustomButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
          >
            Update
          </CustomButton>
        </Box>
      </form>
    </>
  );
}

export default AccountSettingsContent;
