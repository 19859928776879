import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { change_Password } from "./accountSettingsAPI";

const initialState = {
  loading: false,

  current_password: "",
  new_password: "",
  confirm_password: "",
};

const actions = {
  CHANGEPASSWORD: "changePassword/CHANGEPASSWORD",
};

export const changePassword = createAsyncThunk(
  actions.CHANGEPASSWORD,
  async (vendor_id) => {
    const response = await change_Password(vendor_id);
    return response;
  }
);

export const changePasswordSlice = createSlice({
  name: "changepassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        const {
          message: msg,
          current_password,
          new_password,
          confirm_password,
          success,
        } = action.payload;

        state.loading = false;
        state.new_password = new_password;
        state.current_password = current_password;
        state.confirm_password = confirm_password;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })

      .addCase(changePassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const changePasswordActions = changePasswordSlice.actions;
export const { resetdashBoardDetails } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
